import React, { useState } from 'react';
import {Container,Row,Col,Card,Form,FormGroup,Label,Input,Button} from 'reactstrap' 
import { CREATEPASSWORD,NewPassword,RetypePassword,Done} from "../../constant";
import { postWithData, get, postWithDataMultiform, postWithDataMultiformFile } from '../../services/api'
import { toast } from 'react-toastify';

const Resetpwd = (props) => {

  const [passwords, setPasswords] = useState({ newPassword: '', repeatPassword: '' });
  const [error, setError] = useState('');

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPasswords({ ...passwords, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*[@#$%^&*]).{12,}$/;
    if (passwords.newPassword !== passwords.repeatPassword) {
      setError('Las contraseñas no coinciden.');
    } else if (!passwordRegex.test(passwords.newPassword)) {
      setError('La contraseña debe contener al menos 12 caracteres, una letra mayúscula, una letra minúscula, un número y no debe contener caracteres especiales.');
    } else {
      setError('');

      let dataComp = {
        password : passwords.newPassword
    }

      postWithDataMultiform("api/user/resetpass", dataComp).then((result) => {
        //console.log(result);
        if (result.success == true) {
            toast.success("Contraseña cambiada de forma satisfactoria!");
            window.location.href = "/dashboard/default";
        }
      })
    }
  };

    return (
    <div className="page-wrapper">
      <Container fluid={true}>
        <div className="authentication-main mt-0">
          <Row>
            <Col md="12" className="p-0">
              <div className="auth-innerright auth-minibox">
                <div className="authentication-box auth-minibox1">
                  <Card className="mt-4 p-4">
                    <Form className="theme-form" onSubmit={handleSubmit}>
                      <h5 className="f-16 mb-3 f-w-600">{"CAMBIAR CONTRASEÑA"}</h5>
                      <FormGroup>
                        <Label className="col-form-label">{"Nueva Contraseña"}</Label>
                        <Input className="form-control" type="password"  name="newPassword" placeholder="*****" value={passwords.newPassword} onChange={handleChange}/>
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label">{"Repite Contraseña"}</Label>
                        <Input className="form-control" type="password" name="repeatPassword" placeholder="*****" value={passwords.repeatPassword} onChange={handleChange}/>
                      </FormGroup>
                      {error && <div className="text-danger">{error}<br/><br/><br/></div>}
                      <FormGroup className="form-row mb-0">
                        <Col md="2">
                          <Button color="primary" type="submit">{"Cambiar"}</Button>
                        </Col>
                      </FormGroup>
                    </Form>
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
    );
}

export default Resetpwd;