import {toast } from 'react-toastify';

export const ToastInfo = (dataProps) => {
    
    toast.info(dataProps, {
        position: "top-right",
        autoClose: 20000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
}

export const ToastWarn = (dataProps) => {
    
    toast.error(dataProps, {
        position: "top-right",
        autoClose: 20000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
}