import React, { Fragment,useState,useEffect} from 'react';

import Breadcrumb from '../layout/breadcrumb'
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component'
import * as XLSX from 'xlsx';
import moment from 'moment';
import {Container,Row,Col,Card,CardBody,Button,ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter, Form} from 'reactstrap'
import { get } from '../services/api'
import '../assets/css/tablestyle.css';

const CandidateList = () =>  {

  const [listClientData, setListClientData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visibleSearch, setVisibleSearch] = useState(true);
  const [searchTextCode, setSearchTextCode] = useState('');
  const [searchTextName, setSearchTextName] = useState('');
  const [searchTextLegalName, setSearchTextLegalName] = useState('');
  const [searchTextContact, setSearchTextContact] = useState('');
  const [searchTextPhone, setSearchTextPhone] = useState('');
  const [searchTextEmail, setSearchTextEmail] = useState('');
  const [visibleModalDelete, setVisibleModalDelete] = useState(false);
  const [idClient, setIdClient] = useState(null);
  const [nameClient, setNameClient] = useState(null);

  useEffect(() => {
    getListCandidate();
  },[0])

  const getListCandidate = async () => {
    
    try {
      let dataList = [];

      get("api/client/list").then((result) => { 
        if(result.data.length > 0){
          
          result.data.forEach(element => {
            let item = {
                "id": element.id,
                "client_code": element.client_code,
                "type_identity": element.type_identity,
                "number_identity": element.number_identity,
                "name": element.name,
                "legal_name": element.legal_name,
                "phone": element.phone,
                "email": element.email,
                "country": element.country,
                "interlocutor": element.interlocutor,
                "actions": 
                <ButtonGroup>
                  <Button style={{ padding: 5, hight: '35px', width: '35px' }} color='red'  title='Eliminar' onClick={() => openModalDelete(element.id, element.name)}><i className= "icon-trash"></i></Button>
                </ButtonGroup>
            }
            dataList.push(item);
          });
          setListClientData(dataList);
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  const closeModal = () => {
    setVisibleModalDelete(false);
    setNameClient('');
    setIdClient('');
  };

  const openModalDelete = (id, name) => {
    setNameClient(name);
    setIdClient(id);
    setVisibleModalDelete(true);
  }

  const deleteRegister = () => {
    let user_id = localStorage.getItem('Id');
    console.log(idClient)
    try {
      get("api/client/delete/" + idClient + '/' + user_id).then((result) => {
        console.log(result.data)
        const newDatalist = listClientData.filter(item => item.id !== idClient);
        setListClientData(newDatalist);
        setVisibleModalDelete(false);
        toast.success(`Se elimino de forma satisfactoria.`);
                    
      });
    } catch (error) {
      console.log(error)
    }
  }

  const tableColumns = [
    
    /*{
      name: 'Acciones',
      selector: 'actions',
      sortable: true,
      center:true,
    },*/
    {
      name:'Código',
      selector:'client_code',
      sortable:true,
      //center:true
    },
    {
      name: 'Nombre',
      selector: 'name',
      sortable: true,
      center:false,
    },
    {
      name: 'Nombre Legal',
      selector: 'legal_name',
      sortable: true,
      center:false,
    },
    {
      name: 'Interlocutor',
      selector: 'interlocutor',
      sortable: true,
      //center:true,
    },
    {
      name: 'Telefono',
      selector: 'phone',
      sortable: true,
      center:false,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
      center:false,
    },
    {
      name: 'Acciones',
      selector: 'actions',
      sortable: true,
      center: true,
      width: '200px'
    }
  ]
  
  const handleToggleVisibleSearch = () => {
    setVisibleSearch(!visibleSearch);
  };




  const handleSearch = (e) => {

    const tempTableData = listClientData;
    let textSearch = e.target.value;
    let filteredData;

    let tempSearchTextCode = searchTextCode
    let tempSearchTextName = searchTextName
    let tempSearchTextLegalName = searchTextLegalName
    let tempSearchTextContact = searchTextContact
    let tempSearchTextPhone = searchTextPhone
    let tempSearchTextEmail = searchTextEmail

    switch (e.target.name) {
      case "name_search":
        setSearchTextName(textSearch)
        tempSearchTextName = textSearch;
        break;
      case "phone_search":
        setSearchTextPhone(textSearch)
        tempSearchTextPhone = textSearch;
        break;
      case "email_search":
        setSearchTextEmail(textSearch)
        tempSearchTextEmail = textSearch;  
        break;
      case "conctact_search":
        setSearchTextContact(textSearch)
        tempSearchTextContact = textSearch;
        break;
      case "legal_name_search":
        setSearchTextLegalName(textSearch)
        tempSearchTextLegalName = textSearch;
        break;
      case "code_search":
        setSearchTextCode(textSearch)
        tempSearchTextCode = textSearch;
        break;
    }

   

    filteredData = tempTableData.filter((item) => item.name.toLowerCase().includes((tempSearchTextName).toLowerCase()));
    filteredData = filteredData.filter((item) => item.phone.toLowerCase().includes((tempSearchTextPhone).toLowerCase()));
    /*filteredData = filteredData.filter((item) => item.name.toLowerCase().includes((tempSearchTextPosition).toLowerCase()));
    filteredData = filteredData.filter((item) => getBadgeTextContent(item.priority).toLowerCase().includes((tempSearchTextPriority).toLowerCase()));
    filteredData = filteredData.filter((item) => getBadgeTextContent(item.enabled).toLowerCase().includes((tempSearchTextStatus).toLowerCase()));*/

    setListClientData(filteredData);

  };

  const handleExport = () => {
    var candidateExcelData = listClientData.map(cliente => {
      return {
        "Código": cliente.client_code,
        "Nombre": cliente.name,
        "Nombre Legal": cliente.legal_name,
        "Interlocutor": cliente.interlocutor,
        "Teléfono": cliente.phone,
        "Email": cliente.email
      }
    })
    var worksheet = XLSX.utils.json_to_sheet(candidateExcelData);
    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `rrhh_clientes_${moment().format()}.xlsx`);
  }

    return (
        <Fragment>
          <Breadcrumb parent="" title="Lista de Clientes"/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                            <div className="toggle-container">
                              {/*<Button className="btn-search" color="primary" 
                              onClick={handleToggleVisibleSearch}>{"Buscar Registro"}</Button>*/}
                              <Button color='success' className="btn-search pull-right"
                                onClick={handleExport}>
                                  <i className="fa fa-file-excel-o"></i> 
                                  {" Exportar"}
                              </Button>
                              <div className={`content ${visibleSearch ? 'visible' : 'hidden'}`}>
                                <Row>
                                  <Col sm="2">
                                    <div className="search-container">
                                      <input type="text" className="form-control" name="code_search" value={searchTextCode} onChange={handleSearch} placeholder="Codigo" />
                                    </div>
                                  </Col>
                                  <Col sm="2">
                                    <div className="search-container">
                                      <input type="text" className="form-control" name="name_search" value={searchTextName} onChange={handleSearch} placeholder="Nombre" />
                                    </div>
                                  </Col>
                                  <Col sm="2">
                                    <div className="search-container">
                                      <input type="text" className="form-control" name="legal_name_search" value={searchTextLegalName} onChange={handleSearch} placeholder="Nombre Legal" />
                                    </div>
                                  </Col>
                                  <Col sm="2">
                                    <div className="search-container">
                                      <input type="text" className="form-control" name="contact_search" value={searchTextContact} onChange={handleSearch} placeholder="Persona de Contacto" />
                                    </div>
                                  </Col>
                                  <Col sm="2">
                                    <div className="search-container">
                                      <input type="text" className="form-control" name="phone_search" value={searchTextPhone} onChange={handleSearch} placeholder="Teléfono" />
                                    </div>
                                  </Col>
                                  <Col sm="2">
                                    <div className="search-container">
                                      <input type="text" className="form-control" name="email_search" value={searchTextEmail} onChange={handleSearch} placeholder="Email" />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                              <DataTable
                                data={listClientData}
                                pagination
                                columns={tableColumns}
                                striped={true}
                                persistTableHead
                                searchable={true}
                                paginationPerPage={30}  
                                noDataComponent={<div>No hay datos disponibles</div>}
                                progressPending={loading}
                                progressComponent={<div className="loader-box"><div className="loader-9"></div></div>} // Componente personalizado para el indicador de carga
                                customStyles={{
                                  head: {
                                    style: {
                                      textAlign: 'center',
                                    },
                                  },
                                }}
                              />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                
            </Container>

            <Modal isOpen={visibleModalDelete} toggle={openModalDelete}>
              <Form className="theme-form">
                <ModalHeader toggle={openModalDelete}>
                  {"Elimiar Cliente"}
                </ModalHeader>
                <ModalBody>
                  <Row>
                    <Col>
                      {"¿Estas seguro de eliminar el cliente: "}{nameClient}{"?"}
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary mr-1" onClick={deleteRegister} type="button">{"Si"}</Button>
                  <Button color="secondary" onClick={closeModal}>{"Cancelar"}</Button>
                </ModalFooter>
              </Form>
            </Modal>
        </Fragment>
    );

};

export default CandidateList;