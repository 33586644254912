import React, {Fragment, useState,useEffect} from 'react';
import Breadcrumb from '../layout/breadcrumb'
import {useForm} from 'react-hook-form'

import Select from 'react-select'   
import five from '../assets/images/job-search/5.jpg';
import {Container,Row,Col,Card,CardBody, CardHeader,Media,Button,Form,FormGroup,Label,Input} from 'reactstrap'
import {Cancel} from "../constant";
import { toast } from 'react-toastify';
import { postWithData, get } from '../services/api'


const AddClient = (props) => {
    
    // eslint-disable-next-line
    const { register, handleSubmit, formState: { isDirty, isValid, errors }} = useForm(
        { mode: "all", shouldUseNativeValidation: true });
    
    const [role, setRole] = useState([]);
    const [listRoleShow, setListRoleShow] = useState([]);
    const [disabledButton, setDisabledButton] = useState(false);

    useEffect(() => {
        getListRoles();
      },[0])

    const handleSelectRole = (data) => {
        setRole(data.value)
    };
    
    const onSubmit = (data, e) => {
        e.preventDefault();
        setDisabledButton(true);
        let dataFull = {
            ...data,
            role_id : role
        }
        
        if (dataFull !== '') {
            
            postWithData("api/user/add", dataFull).then((result) => { 
              if(result.success === true){
                e.target.reset();
                toast.success("Usuario agregado correctamente.", {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
              }
            })
            .finally(()=>setDisabledButton(false));
        } else {
          errors.showMessages();
          setDisabledButton(false);
        }
      };
    
    const getListRoles = async () => {
        let dataRes = [];
        try {
            let dataListRoleShow = [];
            get("api/role/list").then((result) => { 
                if (result.data.length > 0) {
                    result.data.forEach(element => {
                        let item = { value: element.id, label: element.name };
                        dataListRoleShow.push(item);
                        console.log(item)
                    });
                    setListRoleShow(dataListRoleShow);
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Fragment>
            <Breadcrumb parent="Usuarios" title="Nuevo Usuario"/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12" md="12">
                        <Card>
                            <CardHeader>
                                <Media>
                                    <img className="img-40 img-fluid m-r-20" src={five} alt="" />
                                    <Media body>
                                        <h6 className="f-w-600">{"Usuario"}</h6>
                                        <p>{"Ingresa los datos del Usuario"}</p>
                                    </Media>
                                </Media>
                            </CardHeader>
                            <CardBody>
                                <div className="job-description">
                                    <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                                
                                        <Row>
                                            <Col md={6} sm={12} ç>
                                                <FormGroup>
                                                    <Label htmlFor="name">{"Nombre"}:<span className="font-danger">*</span></Label>
                                                    <Input className="form-control"  id="name" name="name" type="text" placeholder="" innerRef={register({ required: true })} />
                                                    <span style={{ color: "red" }}>{errors.name && 'Verifica el Nombre'}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} sm={12} ç>
                                                <FormGroup>
                                                    <Label htmlFor="email">{"Email"}:<span className="font-danger">*</span></Label>
                                                    <Input className="form-control"  id="email" name="email" type="email" placeholder="" innerRef={register({ required: true })} />
                                                    <span style={{ color: "red" }}>{errors.email && 'Verifica el Email'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6} sm={12} ç>
                                                <FormGroup>
                                                    <Label htmlFor="password">{"Password"}:<span className="font-danger">*</span></Label>
                                                    <Input className="form-control" id="password" name="password" type="password" placeholder="" innerRef={register({ required: true })} />
                                                    
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} sm={12} ç>
                                                <div className="col-form-label pt-0">{"Perfil"}:</div>
                                                <FormGroup>
                                                    <Select
                                                        onChange={handleSelectRole}
                                                        options={listRoleShow}
                                                        id="role_id" name="role_id"
                                                        innerRef={register({ required: true })} />

                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <div className="card-footer">
                                            <Button color="primary mr-1" type="submit" disabled={!isDirty || !isValid || disabledButton}>{"Agregar Usuario"}</Button>
                                            <Button color="light" type="button" >{Cancel}</Button>
                                        </div>
                                    </Form>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default AddClient;