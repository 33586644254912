import React from 'react';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form'
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import Select from 'react-select'
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap'
import { postWithData, get } from '../services/api'

const ModalEditTracking = ({ listStatusShow, listPositionShow, setVisibleModalEditTracking, visibleModalEditTracking, trackingIdEdit }) => {

  const { register, handleSubmit, errors } = useForm();
  const [disabledDateInterview, setDisabledDateInterview] = useState(true);
  const [requiredDateInterview, setRequiredDateInterview] = useState(false);
  const [placeHolderDate, setPlaceHolderDate] = useState('');
  const [dateInterviewData, setDateInterviewData] = useState();
  const [dateInterview, setDateInterview] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [description, setDescription] = useState('');
  const [numberInterview, setNumberInterview] = useState({});
  const [disableTrackingEditSubmitButton, setDisableTrackingEditSubmitButton] = useState(false);
  const [loading, setLoading] = useState(false);

  const [listReasenReject, setListReasenReject] = useState([]);
  const [checkedValuesReject, setCheckedValuesReject] = useState([]);
  const [showInfoReject, setShowInfoReject] = useState(false);


  const [placeHolderDateIn, setPlaceHolderDateIn] = useState('Seleccione Fecha');
  
  const [dateIncorporation, setDateIncorporation] = useState(null);
  const [dateIncorporationData, setDateIncorporationData] = useState(null);
  const [dateOut, setDateOut] = useState(null);
  const [dateOutData, setDateOutData] = useState(null);
  const [showdateIncorporation, setShowDateIncorporation] = useState(false);
  const [showdateOut, setShowDateOut] = useState(false);
  const [agreedRate, setAgreedRate] = useState('')
  const [agreedRateLetters, setAgreedRateLetters] = useState('')

  const [requiredDateIncorporation, setRequiredDateIncorporation] = useState(false);
  const [requiredDateOut, setRequiredDateOut] = useState(false);

  useEffect(() => {
    if(visibleModalEditTracking){
      getTracking();
      getListReasenReject();
    }
    if (!visibleModalEditTracking) {
      setSelectedPosition('');
      setSelectedStatus('');
      setDescription('');
      setDisabledDateInterview(true);
      setRequiredDateInterview(false);
      setPlaceHolderDate('');
      setDateInterviewData(null);
      setDateInterview(null);
      setNumberInterview('');
      setDisableTrackingEditSubmitButton(false);
    }
  }, [visibleModalEditTracking]);

  const getTracking = async () => {
    setLoading(true);
    try {
      get('api/candidate/tracking/' + trackingIdEdit).then((result) => {
        if (result.success) {
          setSelectedPosition(listPositionShow.find(item => item.value === result.data.position_id));
          handleSelectStatus({ value: result.data.status_id })
          setDescription(result.data.comment)
          handleSelectNumberInterview({value: result.data.number_interview, label: result.data.number_interview});
          setLoading(false);

          setAgreedRate(result.data.agreed_rate);
          setAgreedRateLetters(result.data.agreed_rate_letters);

          if(result.data.date_interview){
            handleChangeDateInterview(result.data.date_interview);
          }

          if(result.data.incorporation_date){
            handleChangeDateIncorporation(result.data.incorporation_date);
          }

          if(result.data.out_date){
            handleChangeDateOut(result.data.out_date);
          }

          if(result.data.date_inerview_1) {
            handleChangeDateInterview(result.data.date_inerview_1);
            handleSelectNumberInterview({value: 1, label: 1});
          } else if(result.data.date_inerview_2) {
            handleChangeDateInterview(result.data.date_inerview_2);
            handleSelectNumberInterview({value: 2, label: 2});
          } else if(result.data.date_inerview_3) {
            handleChangeDateInterview(result.data.date_inerview_3);
            handleSelectNumberInterview({value: 3, label: 3});
          } else if(result.data.date_inerview_4) {
            handleChangeDateInterview(result.data.date_inerview_4);
            handleSelectNumberInterview({value: 4, label: 4});
          } else if(result.data.date_inerview_5) {
            handleChangeDateInterview(result.data.date_inerview_5);
            handleSelectNumberInterview({value: 5, label: 5});
          }

          //Data motivo de rechazo
          
          if(result.data_reject.length){
            let arrayIdS = [];
            result.data_reject.forEach(element => {
              arrayIdS.push(element.rejection_reason_id)
            });
            setCheckedValuesReject(arrayIdS);
          }

        }
        console.log(result);
      });
    } catch (error) {
      console.log(error)
    }
  }

  const getListReasenReject = async () => {
    let dataListShow = [];
    let dataListId = [];
    get('api/rejection_reason/list').then((result) => {
      if (result.data.length > 0) {
        result.data.forEach(element => {
          dataListShow.push(element);
          dataListId.push(element.id)
        });
        setListReasenReject(dataListShow);
      }
    });

  }

  const toggleModalEditTracking = () => {
    setVisibleModalEditTracking(!visibleModalEditTracking);
  }


  const handleSelectStatus = (data) => {
    setShowDateIncorporation(false)

    let idsRechazos = [1,3,4,5];

    // if (data.value == "1" || data.value == "3") {
    //   console.log("Desbloqueo Fecha");
    //   setDisabledDateInterview(false);
    //   setRequiredDateInterview(true)
    //   setPlaceHolderDate('Seleccione Fecha')
    // } else {
    //   setDisabledDateInterview(true);
    //   setDateInterviewData(null)
    //   setDateInterview(null)
    //   setRequiredDateInterview(false)
    //   setPlaceHolderDate('No puede seleccionar')
    // }
    let statusCurrent = listStatusShow.find(item => item.value === data.value)
    setSelectedStatus(statusCurrent);
    console.log(statusCurrent)
    //Aceptado
    if(statusCurrent.value == 7){
      setShowDateIncorporation(true)
    }else if(idsRechazos.indexOf(data.value) !== -1){
      setShowInfoReject(true)
    }else if(statusCurrent.value == 11){
      setShowDateOut(true)
    }
  };

  const handleCheckboxChangeReject = (id, isChecked, arrayIds) => {
    console.log("ARRAY ANTES", arrayIds)


    if(isChecked === true){
      let arrayInclude = arrayIds.push(id)
      //setCheckedValuesReject(arrayInclude)
    }else{
      
      const arrayExclude = arrayIds.filter((value) => value !== id);
      setCheckedValuesReject(arrayExclude)
    }
    
    //console.log(checkedValuesReject)
  };

  const handleSelectNumberInterview = (data) => {
    setNumberInterview({value: data.value || "", label: data.value  || ""});
    if(data.value){
      setRequiredDateInterview(true);
      setDisabledDateInterview(false);
      setPlaceHolderDate('Seleccione Fecha')
    } else {
      setRequiredDateInterview(false);
      setDisabledDateInterview(true);
      setPlaceHolderDate('No puede seleccionar')
    }
  }

  const handleChangeDateInterview = date => {
    let dateInterview = new Date(date);
    let month = ((dateInterview.getMonth() + 1) < 10) ? "0" + (dateInterview.getMonth() + 1) : (dateInterview.getMonth() + 1);
    let dateFinal = dateInterview.getFullYear() + "-" + month + "-" + dateInterview.getDate()
    console.log("date", date);
    console.log("dateInterview", dateInterview);
    console.log("dateFinal", dateFinal);
    setDateInterview(dateInterview)
    setDateInterviewData(dateFinal)
  };

  const handleChangeDateIncorporation = date => {
    let dateIncorporation = new Date(date);
    let month = ((dateIncorporation.getMonth() + 1) < 10) ? "0" + (dateIncorporation.getMonth() + 1) : (dateIncorporation.getMonth() + 1);
    let dateFinal = dateIncorporation.getFullYear() + "-" + month + "-" + dateIncorporation.getDate()
    setDateIncorporation(dateIncorporation)
    setDateIncorporationData(dateFinal)
  };

  const handleChangeDateOut = date => {
    let dateOut = new Date(date);
    let month = ((dateOut.getMonth() + 1) < 10) ? "0" + (dateOut.getMonth() + 1) : (dateOut.getMonth() + 1);
    let dateFinal = dateOut.getFullYear() + "-" + month + "-" + dateOut.getDate()
    setDateOut(dateOut)
    setDateOutData(dateFinal)
  };

  const handleChangeDecimal = (event) => {
    const { value } = event.target;

    // Expresión regular para permitir solo números y un punto opcional para los decimales
    const regex = /^\d*\.?\d*$/;

    if (regex.test(value) || value === '') {
      setAgreedRate(value)
      // Llama a la función de cambio pasada como prop
      /*if (this.props.onChange) {
        this.props.onChange(value);
      }*/
    }
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    setDisableTrackingEditSubmitButton(true);
    setLoading(true);
    let dataTracking = {
      ...data,
      id: trackingIdEdit,
      status_id: selectedStatus.value,
      date_interview: dateInterviewData,
      number_interview: numberInterview.value,
      date_incorporation: dateIncorporationData,
      date_out: dateOutData,
      rejection_reason: checkedValuesReject
    }

    postWithData("api/candidate/tracking/save", dataTracking).then((result) => {
        if (result.success === true) {
          setVisibleModalEditTracking(!visibleModalEditTracking);
        } else {
          toast.error("Hubo un error al editar el historial")
          setVisibleModalEditTracking(!visibleModalEditTracking);
        }
        setLoading(false);
        setDateInterview('')
        setDateInterviewData('')
        setDateIncorporation('')
        setDateIncorporationData('')
        setDateOut('')
        setDateOutData('')
    });
  };



  return (
    <Modal size="lg" isOpen={visibleModalEditTracking} toggle={toggleModalEditTracking}>
      <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleModalEditTracking}>
          {"Editar Historia al Candidato"}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg="6">
              <div className="col-form-label pt-0">{"Estado"}:</div>
              <FormGroup>
                <Select
                  onChange={handleSelectStatus}
                  options={listStatusShow}
                  id="status"
                  name="status"
                  value={selectedStatus}
                  isDisabled={true}
                  />
              </FormGroup>
            </Col>
            <Col lg="3">
                <div className="col-form-label pt-0">{"Número Entrevista"}:</div>
                <FormGroup>
                  <Select
                    onChange={handleSelectNumberInterview}
                    options={[
                      { value: "", label: "" },
                      { value: 1, label: 1 },
                      { value: 2, label: 2 },
                      { value: 3, label: 3 },
                      { value: 4, label: 4 },
                      { value: 5, label: 5 },
                    ]}
                    value={numberInterview}
                    id="number_interview" name="number_interview"
                    innerRef={register()} />
                </FormGroup>
              </Col>
            <Col lg="3">
              <div className="col-form-label pt-0">{"Fecha de Entrevista"}</div>
              <FormGroup>
                <DatePicker selected={dateInterview}
                  className="form-control"
                  disabled={(disabledDateInterview) ? "disabled" : ""}
                  required={requiredDateInterview}
                  placeholderText={placeHolderDate}
                  onChange={handleChangeDateInterview}
                />
              </FormGroup>
            </Col>
          </Row>
          {showdateIncorporation && (
              <Row>
                <Col>
                  <div className="col-form-label pt-0">{"Fecha de Incorporación"}:</div>
                  <FormGroup>
                    <DatePicker 
                      selected={dateIncorporation} 
                      className="form-control" 
                      placeholderText={placeHolderDateIn}
                      onChange={handleChangeDateIncorporation}
                      required= {requiredDateIncorporation}
                      type="text"
                      innerRef={register({required: requiredDateIncorporation})} />
                  </FormGroup>
                </Col>
                <Col>
                  <div className="col-form-label pt-0">{"Fecha de Salida"}:</div>
                  <FormGroup>
                    <DatePicker 
                      selected={dateOut} 
                      className="form-control" 
                      placeholderText={placeHolderDateIn}
                      onChange={handleChangeDateOut}
                      type="text"
                      innerRef={register()} />
                  </FormGroup>
                </Col>
                
                <Col>
                  <div className="col-form-label pt-0">{"Tarifa Final"}:</div>
                  <FormGroup>
                    <Input className="form-control" id="agreed_rate" 
                    name="agreed_rate" type="decimal" 
                    placeholder="" 
                    defaultValue={agreedRate || ''}
                    innerRef={register({required: requiredDateIncorporation})}
                    onChange={handleChangeDecimal}/>
                  </FormGroup>
                </Col>
                <Col>
                    <div className="col-form-label pt-0">{"Tarifa Final (En letras)"}:</div>
                    <FormGroup>
                      <Input className="form-control" id="agreed_rate_letters" 
                      name="agreed_rate_letters" 
                      type="text" placeholder="" 
                      defaultValue={agreedRateLetters || ''}
                      innerRef={register({required: requiredDateIncorporation})} />
                    </FormGroup>
                  </Col>
                </Row>
            )}
            {showdateOut && (
              <Row>
                <Col>
                  <div className="col-form-label pt-0">{"Fecha de Salida"}:</div>
                  <FormGroup>
                    <DatePicker 
                      selected={dateOut} 
                      className="form-control" 
                      placeholderText={placeHolderDateIn}
                      onChange={handleChangeDateOut}
                      type="text"
                      innerRef={register({required: showdateOut})} />
                  </FormGroup>
                </Col>
                </Row>
            )}
          <Row>
            <Col>
              <div className="col-form-label pt-0">{"Vacante"}:</div>
              <FormGroup>
                <Select
                  id="position"
                  name='position'
                  options={listPositionShow}
                  value={selectedPosition}
                  isDisabled={true}
                  />
              </FormGroup>
            </Col>
          </Row>
          {showInfoReject && (
              <Row>
                <Col>
                  <div className="col-form-label pt-0">{"Motivo de Rechazo"}:</div>
                  <Row>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {listReasenReject.map((item) => (
                      <Col key={item.id}>
                        <FormGroup>
                        <Input id={`checkbox-${item.id}`} 
                        type="checkbox" 
                        checked={checkedValuesReject.includes(item.id)}
                        onChange={(e) => handleCheckboxChangeReject(item.id, e.target.checked, checkedValuesReject)} />
                        <Label htmlFor={`checkbox-${item.id}`}>{item.name}</Label>
                        </FormGroup>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            )}
          <Row>
            <Col>
              <FormGroup>
                <Label className="col-form-label pt-0">{"Comentario / Observación"}:<span className="font-danger">*</span></Label>
                <Input
                  type="textarea"
                  className="form-control btn-square"
                  name="comment"
                  rows="3"
                  defaultValue={description || ''}
                  innerRef={register()}
                />
              </FormGroup>
            </Col>
          </Row>
          {<div className={`content ${loading ? 'visible loader-box' : 'hidden'}`}><div className="loader-8"></div></div>}
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggleModalEditTracking} color="secondary">{"Cancelar"}</Button>
          <Button disabled={disableTrackingEditSubmitButton} color="primary mr-1" type="submit">{"Guardar Historia"}</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

export default ModalEditTracking;