import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../layout/breadcrumb'
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import { Typeahead } from 'react-bootstrap-typeahead';
import DatePicker from "react-datepicker";
import five from '../assets/images/job-search/5.jpg';
import Select from 'react-select'
import InputRange from "react-input-range";
import { Container, Row, Col, Card, CardBody, CardHeader, Media, Button, Form, FormGroup, Label, Input} from 'reactstrap'
import { Cancel } from "../constant";
import { toast } from 'react-toastify';
import { get, postWithDataMultiform } from '../services/api'

const AddPosition = (props) => {

    let { id_client } = useParams();
    // eslint-disable-next-line
    const { register, handleSubmit, formState: { isDirty, isValid, errors } } = useForm(
        { mode: "all", shouldUseNativeValidation: true });

    const [listTechnologiesData, setListTechnologiesData] = useState([]);
    const [listTechnologiesDataShow, setListTechnologiesDataShow] = useState([]);
    const [listTechnologiesDataShowReserv, setListTechnologiesDataShowReserv] = useState([]);

    const [listClientsData, setListClientsData] = useState([]);
    const [idClient, setIdClient] = useState(null);
    const [nameClient, setNameClient] = useState(null);

    const [multipleTecMin, setMultipleTecMin] = useState([]);
    const [multipleTecDes, setMultipleTecDes] = useState([]);
    const [multipleClient, setMultipleClient] = useState();
    const [multipleType, setMultipleType] = useState();

    const [dateIntensiveBegin, setDateIntensiveBegin] = useState();
    const [dateIntensiveBeginDB, setDateIntensiveBeginDB] = useState();
    const [dateIntensiveEnd, setDateIntensiveEnd] = useState();
    const [dateIntensiveEndDB, setDateIntensiveEndDB] = useState();

    const [dateHorarioBegin, setDateHorarioBegin] = useState();
    const [dateHorarioBeginDB, setDateHorarioBeginDB] = useState();
    const [dateHorarioEnd, setDateHorarioEnd] = useState();
    const [dateHorarioEndDB, setDateHorarioEndDB] = useState();

    const [visibleTime, setVisibleTime] = useState(false);

    const [multipleMainMarket, setMultipleMainMarket] = useState([]);
    const [listMainMarketShow, setListMainMarketShow] = useState([]);
    

    const initialSliderRange = {
        value: 500,
        nagative: -10,
        foramtedLabel: 10,
        disabled: 10,
        foramtedLabelKg: 500,
        draggable: {
            min: 15000,
            max: 30000,
        },
    };
    const [valuesRange, setValuesRange] = useState(initialSliderRange);

    const initialSliderRangeFee = {
        value: 15,
        nagative: -5,
        foramtedLabel: 5,
        disabled: 5,
        foramtedLabelKg: 5,
        draggable: {
            min: 15,
            max: 40,
        },
    };
    const [valuesRangeFee, setValuesRangeFee] = useState(initialSliderRangeFee);
    const [disabledButton, setDisabledButton] = useState(false);

    const [multipleResponsable, setMultipleResponsable] = useState(null);
    const [listResponsableDataShow, setListResponsableDataShow] = useState([]);
    const [listResponsableData, setListResponsableData] = useState([]);
    /*
    const [multiplePosition, setMultiplePosition] = useState(null);
    const [multipleTec, setMultipleTec] = useState([]);
    const [multipleCategory, setMultipleCategory] = useState([]);
    const [multipleGrade, setMultipleGrade] = useState([]);
    const [multipleLang, setMultipleLang] = useState(null);
    const [startDate, setStartDate] = useState(null);*/

    useEffect(() => {

        getListTechnologies();
        getListClients();
        getListMainMarket();
        getListResponsable();
        /*getListPosition();*/
    }, [0])

    const getListTechnologies = async () => {
        let dataRes = [];
        try {
            get("api/technology/list").then((result) => {

                if (result.success == true) {
                    result.data.forEach(element => {
                        dataRes.push(element.name)
                    });
                    setListTechnologiesData(result.data);
                    setListTechnologiesDataShow(dataRes);
                    setListTechnologiesDataShowReserv(dataRes);
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    const getListClients = async () => {
        let dataRes = [];
        try {
            get("api/client/list").then((result) => {

                if (result.success == true) {
                    result.data.forEach(element => {
                        dataRes.push({ value: element.id, label: element.name })

                        if (id_client == element.id) {
                            setIdClient(id_client);
                            setNameClient(element.name)
                        }
                    });
                    setListClientsData(dataRes)

                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    const getListMainMarket = async () => {
        try {
            let dataListMainMarketShow = [];
            get('api/mainmarket/list').then((result) => {
                if (result.data.length > 0) {
                    result.data.forEach(element => {
                        let item = { value: element.id, label: element.name };
                        dataListMainMarketShow.push(item);
                    });
                    setListMainMarketShow(dataListMainMarketShow);
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    const getListResponsable = async () => {
        let dataRes = [];
        try {
            get("api/user/list/active").then((result) => {

                if (result.success == true) {
                    result.data.forEach(element => {
                        dataRes.push(element.name_user)
                    });
                    setListResponsableData(result.data)
                    setListResponsableDataShow(dataRes)
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    const handleSelectTecMin = (selectedOption) => {
        setMultipleTecMin(selectedOption);
        setListTechnologiesDataShowReserv(listTechnologiesDataShow);
    };
    const handleSelectTecDes = (selectedOption) => {
        setMultipleTecDes(selectedOption);
        setListTechnologiesDataShowReserv(listTechnologiesDataShow);
    };

    const handleInputChange = (inputValue) => {
        //Para refrescar las opciones y que no salgan strings en las opciones (j, ja, jav, java)
        setListTechnologiesDataShow(listTechnologiesDataShowReserv);
        // Si el usuario ha ingresado uns tecnología que no está en la lista, mostrarlo en el dropdown
        if (inputValue && !listTechnologiesDataShow.includes(inputValue)) {
            setListTechnologiesDataShow((prevList) => [...prevList, inputValue]);
        }
    };

    const handleSelectClient = (data) => {
        setMultipleClient(data.value)
    };

    const handleSelectType = (data) => {
        setMultipleType(data.value)
        if(data.value == "Definido"){
            setVisibleTime(true);
        }else{
            setVisibleTime(false); 
        }
    };

    const handleSelectMainMarket = (data) => {
        setMultipleMainMarket(data.value)
    };

    const handleChangeDateIntensiveBegin = date => {
        setDateIntensiveBegin(date)

        let fecha = new Date(date);
        let year = fecha.getFullYear();
        let month = String(fecha.getMonth() + 1).padStart(2, "0");
        let day = String(fecha.getDate()).padStart(2, "0");
        let fechaFormateada = `${year}-${month}-${day}`;

        setDateIntensiveBeginDB(fechaFormateada)
    };

    const handleChangeDateIntensiveEnd = date => {
        setDateIntensiveEnd(date)

        let fecha = new Date(date);
        let year = fecha.getFullYear();
        let month = String(fecha.getMonth() + 1).padStart(2, "0");
        let day = String(fecha.getDate()).padStart(2, "0");

        let fechaFormateada = `${year}-${month}-${day}`;

        setDateIntensiveEndDB(fechaFormateada)
    };

    const handleChangeDateBegin = date => {
        setDateHorarioBegin(date)

        let hora = date.getHours();
        let minutos = date.getMinutes();

        let horaFormateada = `${hora}:${minutos}`;
        setDateHorarioBeginDB(horaFormateada)
    };

    const handleChangeDateEnd = date => {
        setDateHorarioEnd(date)

        let hora = date.getHours();
        let minutos = date.getMinutes();

        let horaFormateada = `${hora}:${minutos}`;
        setDateHorarioEndDB(horaFormateada)
    };

    const handleSelectResponsable = (data) => {
        setMultipleResponsable(data)
    };


    const onSubmit = (data, e) => {
        e.preventDefault();
        setDisabledButton(true);
        let dataInsert = {
            ...data,
            client_id: multipleClient,
            type_contract: multipleType,
            range_salary: valuesRange.draggable.min + "-" + valuesRange.draggable.max,
            range_fee: valuesRangeFee.draggable.min + "-" + valuesRangeFee.draggable.max,
            schedule_begin: dateHorarioBeginDB,
            schedule_end: dateHorarioEndDB,
            intensive_begin: dateIntensiveBeginDB,
            intensive_end: dateIntensiveEndDB,
            main_market: multipleMainMarket,
            responsable : multipleResponsable
        }

        if (multipleTecDes.length > 0) {
            let technologies = [];
            multipleTecDes.forEach(item => {
                let foundTec = listTechnologiesData.find(function (element) { return element.name == item });
                if (foundTec) {
                    technologies.unshift(foundTec.id)
                } else {
                    technologies.push(item);
                }
            });
            dataInsert.technology_desirable = technologies;
        }

        if (multipleTecMin.length > 0) {
            let technologies = [];
            multipleTecMin.forEach(item => {
                let foundTec = listTechnologiesData.find(function (element) { return element.name == item });
                if (foundTec) {
                    technologies.unshift(foundTec.id)
                } else {
                    technologies.push(item);
                }
            });
            dataInsert.technology_minimal = technologies;
        }
        
        console.log(dataInsert);
        
        if (data !== '') {
            postWithDataMultiform("api/position/add", dataInsert).then((result) => {
                if (result.success === true) {
                    e.target.reset();
                    toast.success("Vacante agregada correctamente.", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                }
            })
            .finally(()=>setDisabledButton(false));
        } else {
            errors.showMessages();
            setDisabledButton(false);
        }
    };

    const optionsTypeContract = [
        { value: 'Definido', label: 'Definido' },
        { value: 'Indefinido', label: 'Indefinido' },
    ]


    return (
        <Fragment>
            <Breadcrumb parent="Vacantes" title="Nueva Vacante" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12" md="12">
                        <Card>
                            <CardHeader>
                                <Media>
                                    <img className="img-40 img-fluid m-r-20" src={five} alt="" />
                                    <Media body>
                                        <h6 className="f-w-600">{"Vacante"}</h6>
                                        <p>{"Ingresa los datos de la Vacante"}</p>
                                    </Media>
                                </Media>
                            </CardHeader>
                            <CardBody>
                                <div className="job-description">
                                    <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label htmlFor="client">{"Cliente/Marca"}:<span className="font-danger">*</span></Label>
                                                    <Select onChange={handleSelectClient} defaultValue={{ label: nameClient, value: idClient }}
                                                        options={listClientsData} id="client" name="client" innerRef={register({ required: true })} />
                                                    <span style={{ color: "red" }}>{errors.number_identity && 'Selecciona un Cliente'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label htmlFor="name">{"Puesto Vacante"}:<span className="font-danger">*</span></Label>
                                                    <Input className="form-control" id="name" name="name" type="text" placeholder="" innerRef={register({ required: true })} />
                                                    <span style={{ color: "red" }}>{errors.name && 'Verifica el Nombre de la Vacante'}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label htmlFor="name">{"Número de Vacantes"}:<span className="font-danger">*</span></Label>
                                                    <Input required className="form-control" id="number_postion" name="number_postion" max={10} min={1} maxLength={2} type="number" placeholder="" innerRef={register({ required: true })} />
                                                    {/* <span style={{ color: "red" }}>{errors.name && 'Verifica el Número Vacante'}</span> */}
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label htmlFor="main_market">{"Mercado Principal"}:</Label>
                                                    <Select
                                                        onChange={handleSelectMainMarket}
                                                        options={listMainMarketShow}
                                                        id="main_market" name="main_market"
                                                        innerRef={register({ required: true })} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6} sm={12} >
                                                <div className="col-form-label pt-0">{"Responsable"}:</div>
                                                <FormGroup>
                                                    <Typeahead
                                                        id="responsable"
                                                        labelKey="responsable"
                                                        onChange={handleSelectResponsable}
                                                        multiple={true}
                                                        options={listResponsableDataShow}
                                                        placeholder=""
                                                        innerRef={register()}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="col-form-label pt-0">{"Funciones/Responsabilidades"}:</Label>
                                                    <Input type="textarea" className="form-control btn-square" name="description" rows="3" innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <h6 className="mb-0">{"Formación"} </h6>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="col-form-label pt-0">{"Deseable"}:</Label>
                                                    <Input type="textarea" className="form-control btn-square" name="desirable" rows="3" innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="col-form-label pt-0">{"Indispensable"}:</Label>
                                                    <Input type="textarea" className="form-control btn-square" name="indispensable" rows="3" innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <h6 className="mb-0">{"Requisitos"} </h6>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="col-form-label pt-0">{"Requeridos Descripción: "}:</Label>
                                                    <Input type="textarea" className="form-control btn-square" name="required_requirements" rows="3" innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="col-form-label pt-0">{"Deseables Descripción: "}:</Label>
                                                    <Input type="textarea" className="form-control btn-square" name="desirable_requirements" rows="3" innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="col-form-label pt-0">{"Requeridos"}:</div>
                                                <FormGroup>
                                                    <Typeahead
                                                        id="technologies"
                                                        labelKey="technologies"
                                                        onChange={handleSelectTecMin}
                                                        multiple={true}
                                                        options={listTechnologiesDataShow}
                                                        placeholder=""
                                                        onInputChange={handleInputChange} // Para capturar cambios en el input por si es una nueva Tecnología
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <div className="col-form-label pt-0">{"Deseables"}:</div>
                                                <FormGroup>
                                                    <Typeahead
                                                        id="technologies"
                                                        labelKey="technologies"
                                                        onChange={handleSelectTecDes}
                                                        multiple={true}
                                                        options={listTechnologiesDataShow}
                                                        placeholder=""
                                                        onInputChange={handleInputChange} // Para capturar cambios en el input por si es una nueva Tecnología
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="2">
                                                <FormGroup>
                                                    <Label htmlFor="range_salary">{"Rango Salarial"}:</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col md="8">
                                                <FormGroup>
                                                    <InputRange
                                                        draggableTrack
                                                        maxValue={80000}
                                                        minValue={10000}
                                                        step={500}
                                                        value={valuesRange.draggable}
                                                        onChange={(value) =>
                                                            setValuesRange({ ...valuesRange, draggable: value })
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="2">
                                                <FormGroup>
                                                    <Label htmlFor="range_fee">{"Rango de Tarifa"}:</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col md="8">
                                                <FormGroup>
                                                    <InputRange
                                                        draggableTrack
                                                        maxValue={100}
                                                        minValue={10}
                                                        step={5}
                                                        value={valuesRangeFee.draggable}
                                                        onChange={(value) =>
                                                            setValuesRangeFee({ ...valuesRangeFee, draggable: value })
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label htmlFor="type_contrat">{"Duración"}:</Label>
                                                    <Select onChange={handleSelectType} options={optionsTypeContract} id="type_contrat" innerRef={register()} />
                                                    <span style={{ color: "red" }}>{errors.number_identity && 'Verifica el número de documento'}</span>
                                                    <br/>
                                                    <div className={`content ${visibleTime ? 'visible' : 'hidden'}`}>
                                                        <Label htmlFor="type_contrat">{"¿Cuanto tiempo?"}:<span className="font-danger">*</span></Label>
                                                        <Input
                                                            type="text"
                                                            name="timeContract"
                                                            placeholder="Ingrese el tiempo"
                                                            innerRef={register()}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <div className="checkbox checkbox-dark m-squar">
                                                        <Input id="colaborar_para_ioon" name="colaborar_para_ioon" type="checkbox" innerRef={register()} />
                                                        <Label for="colaborar_para_ioon">{"¿Puede colaborar con autónomos contratados por IOON?"}</Label>
                                                    </div>
                                                    <div className="checkbox checkbox-dark m-squar">
                                                        <Input id="remote_work" name="remote_work" type="checkbox" innerRef={register()} />
                                                        <Label for="remote_work">{"Teletrabajo"}</Label>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <div className="checkbox checkbox-dark m-squar">
                                                        <Input id="is_portal" name="is_portal" type="checkbox" innerRef={register()} />
                                                        <Label for="is_portal">{"¿Es portal?"}</Label>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="col-form-label pt-0">{"Horario"}:</Label>
                                                    <Input type="textarea" className="form-control btn-square" name="schedule" rows="3" innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <Row>
                                                            <Col sm="6">
                                                                <div className="col-form-label pt-0">{"Hora inicio:"}</div>
                                                            </Col>
                                                            <Col sm="6">
                                                                <div className="col-form-label pt-0">{"Hora fin:"}</div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm="6">
                                                                <FormGroup>
                                                                    <DatePicker 
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    timeIntervals={15} // Opcional: intervalo de tiempo en minutos
                                                                    timeCaption="Hora"
                                                                    selected={dateHorarioBegin}
                                                                    className="form-control" 
                                                                    timeFormat="HH:mm" // Formato de hora (en formato de 24 horas)
                                                                    dateFormat="hh:mm aa"
                                                                    onChange={handleChangeDateBegin} />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm="6">
                                                                <FormGroup>
                                                                    <DatePicker 
                                                                     showTimeSelect
                                                                     showTimeSelectOnly
                                                                     timeIntervals={15} // Opcional: intervalo de tiempo en minutos
                                                                     timeCaption="Hora"
                                                                    selected={dateHorarioEnd} 
                                                                    className="form-control" 
                                                                    timeFormat="HH:mm" // Formato de hora (en formato de 24 horas)
                                                                    dateFormat="hh:mm aa"
                                                                    onChange={handleChangeDateEnd} />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="checkbox checkbox-dark m-squar">
                                                    <FormGroup>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;<Input id="intensive_day" name="intensive_day" type="checkbox" innerRef={register()} />
                                                        <Label for="intensive_day">{"Jornada Intensiva"}</Label>
                                                    </FormGroup>
                                                </div>
                                            </Col>
                                            <Col>
                                                <Row>
                                                    <Col sm="6">
                                                        <div className="col-form-label pt-0">{"Fecha inicio:"}</div>
                                                    </Col>
                                                    <Col sm="6">
                                                        <div className="col-form-label pt-0">{"Fecha fin:"}</div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm="6">
                                                        <FormGroup>
                                                            <DatePicker 
                                                            selectsStart
                                                            startDate={dateIntensiveBegin}
                                                            endDate={dateIntensiveEnd} 
                                                            selected={dateIntensiveBegin} 
                                                            className="form-control" 
                                                            onChange={handleChangeDateIntensiveBegin} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="6">
                                                        <FormGroup>
                                                            <DatePicker 
                                                            selectsEnd
                                                            startDate={dateIntensiveBegin}
                                                            endDate={dateIntensiveEnd} 
                                                            selected={dateIntensiveEnd} 
                                                            className="form-control" 
                                                            onChange={handleChangeDateIntensiveEnd} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label className="col-form-label pt-0">{"Comentario"}:</Label>
                                                    <Input type="textarea" className="form-control btn-square" name="comment" rows="3" innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="exampleSelect">
                                                        Prioridad
                                                    </Label>
                                                    <Input
                                                        id="priority"
                                                        name="priority"
                                                        type="select"
                                                        innerRef={register()}
                                                    >
                                                        <option value="Baja">
                                                            Baja
                                                        </option>
                                                        <option value="Media">
                                                            Media
                                                        </option>
                                                        <option value="Alta">
                                                            Alta
                                                        </option>
                                                        <option value="Muy Alta">
                                                            Muy Alta
                                                        </option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            {/* <Row>
                                            <Col>
                                                <FormGroup>
                                            <Label for="state_position">
                                                Estado de la vacante
                                            </Label>
                                            <Input
                                                id="state_position"
                                                name="state_position"
                                                type="select"
                                                innerRef={register({ required: true })}
                                            >
                                                <option value="En proceso">
                                                    En proceso
                                                </option>
                                                <option value="Perdido/Cerrado">
                                                    Perdido/Cerrado
                                                </option>
                                                <option value="Parada">
                                                    Parada
                                                </option>   
                                                <option value="Ganada">
                                                    Ganada
                                                </option>
                                            </Input>
                                        </FormGroup>
                                            </Col>
                                        </Row> */}

                                            <Col>
                                                <FormGroup>
                                                    <Label className="col-form-label pt-0">{"Archivo"}:</Label>
                                                    <Input className="form-control" type="file" name="file" innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <div className="card-footer">
                                            <Button color="primary mr-1" type="submit" disabled={!isDirty || !isValid || disabledButton}>{"Agregar Vacante"}</Button>
                                            <Button type="button" color="light">{Cancel}</Button>
                                        </div>
                                    </Form>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default AddPosition;