import React, { Fragment,useState,useEffect} from 'react';
import Breadcrumb from '../layout/breadcrumb'
import DataTable from 'react-data-table-component'
import {tableData} from '../data/dummyTableData'
import {Container,Row,Col,Card,CardBody } from 'reactstrap'
import { get } from '../services/api'
import '../assets/css/tablestyle.css';

const CandidateList = () =>  {

  const [listUserData, setlistUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visibleSearch, setVisibleSearch] = useState(false);
  const [searchTextCode, setSearchTextCode] = useState('');
  const [searchTextName, setSearchTextName] = useState('');
  const [searchTextLegalName, setSearchTextLegalName] = useState('');
  const [searchTextContact, setSearchTextContact] = useState('');
  const [searchTextPhone, setSearchTextPhone] = useState('');
  const [searchTextEmail, setSearchTextEmail] = useState('');

  useEffect(() => {
    getListCandidate();
  },[0])

  const getListCandidate = async () => {
    
    try {
      let dataList = [];

      get("api/user/list").then((result) => { 
        if(result.data.length > 0){
          
          result.data.forEach(element => {
            let item = {
                "id": element.id_user,
                "name": element.name_user,
                "user": element.email,
                "role": element.role_name,
                "actions": ''
                /*<ButtonGroup>
                  <Button color="light" ><a href={"/position/add/"+element.id} title='Agregar Vacante'>{<i className="fa fa-instagram"></i>}</a></Button>
                  <Button color="light" ><a href={"/position/add/"+element.id} title='Agregar Vacante'>{"Editar"}</a></Button>
                </ButtonGroup>*/
            }
            dataList.push(item);
          });
          setlistUserData(dataList);
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  const tableColumns = [
    
    /*{
      name: 'Acciones',
      selector: 'actions',
      sortable: true,
      center:true,
    },*/
    {
        name: 'Nombre',
        selector: 'name',
        sortable: true,
        center:false,
    },
    {
      name: 'Usuario',
      selector: 'user',
      sortable: true,
      center:false,
    },
    {
      name: 'Rol',
      selector: 'role',
      sortable: true,
      center:false,
    },
  ]
  
  const handleToggleVisibleSearch = () => {
    setVisibleSearch(!visibleSearch);
  };


  const handleSearch = (e) => {

    const tempTableData = listUserData;
    let textSearch = e.target.value;
    let filteredData;

    let tempSearchTextCode = searchTextCode
    let tempSearchTextName = searchTextName
    let tempSearchTextLegalName = searchTextLegalName
    let tempSearchTextContact = searchTextContact
    let tempSearchTextPhone = searchTextPhone
    let tempSearchTextEmail = searchTextEmail

    switch (e.target.name) {
      case "name_search":
        setSearchTextName(textSearch)
        tempSearchTextName = textSearch;
        break;
      case "phone_search":
        setSearchTextPhone(textSearch)
        tempSearchTextPhone = textSearch;
        break;
      case "email_search":
        setSearchTextEmail(textSearch)
        tempSearchTextEmail = textSearch;  
        break;
      case "conctact_search":
        setSearchTextContact(textSearch)
        tempSearchTextContact = textSearch;
        break;
      case "legal_name_search":
        setSearchTextLegalName(textSearch)
        tempSearchTextLegalName = textSearch;
        break;
      case "code_search":
        setSearchTextCode(textSearch)
        tempSearchTextCode = textSearch;
        break;
    }

   

    filteredData = tempTableData.filter((item) => item.name.toLowerCase().includes((tempSearchTextName).toLowerCase()));
    filteredData = filteredData.filter((item) => item.phone.toLowerCase().includes((tempSearchTextPhone).toLowerCase()));
    /*filteredData = filteredData.filter((item) => item.name.toLowerCase().includes((tempSearchTextPosition).toLowerCase()));
    filteredData = filteredData.filter((item) => getBadgeTextContent(item.priority).toLowerCase().includes((tempSearchTextPriority).toLowerCase()));
    filteredData = filteredData.filter((item) => getBadgeTextContent(item.enabled).toLowerCase().includes((tempSearchTextStatus).toLowerCase()));*/

    setlistUserData(filteredData);

  };

    return (
        <Fragment>
          <Breadcrumb parent="" title="Usuarios"/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                              <DataTable
                                data={listUserData}
                                pagination
                                columns={tableColumns}
                                striped={true}
                                center={true}
                                persistTableHead
                                searchable={true}
                                noDataComponent={<div>No hay datos disponibles</div>}
                                progressPending={loading}
                                progressComponent={<div className="loader-box"><div className="loader-9"></div></div>} // Componente personalizado para el indicador de carga
                              />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                
            </Container>
        </Fragment>
    );

};

export default CandidateList;