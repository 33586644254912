import React, { Fragment, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom';
import Breadcrumb from '../layout/breadcrumb'
import * as XLSX from 'xlsx';
import moment from 'moment';
import DataTable from 'react-data-table-component'
import { tableData } from '../data/dummyTableData'
import {
  Container, Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, ButtonGroup
} from 'reactstrap'
import { postWithData, get } from '../services/api'
import { toast } from 'react-toastify';
import Select from 'react-select';
import '../assets/css/tablestyle.css';
const CandidateList = () => {

  const [listPositionData, setListPositionData] = useState([]);
  const [listPositionExcelData, setListPositionExcelData] = useState([]);
  const [listPositionDataReserv, setListPositionDataReserv] = useState([]);
  const [listPositionDataReservForFilter, setListPositionDataReservForFilter] = useState([]);
  const [searchTextClient, setSearchTextClient] = useState('');
  const [searchTextPosition, setSearchTextPosition] = useState('');
  const [searchTextCandidate, setSearchTextCandidate] = useState('');
  const [searchTextCodePosition, setSearchTextCodePosition] = useState('');
  const [searchTextPriority, setSearchTextPriority] = useState('');
  const [searchTextStatus, setSearchTextStatus] = useState('');

  const [loading, setLoading] = useState(true);
  const [visibleSearch, setVisibleSearch] = useState(true);

  const [visibleModalListCandidate, setVisibleModalListCandidate] = useState(false);
  const [listCandidates, setListCandidates] = useState([]);
  const [visibleModalAddAddCandidates, setVisibleModalAddAddCandidates] = useState(false);
  const [visibleModalDeletePosition, setVisibleModalDeletePosition] = useState(false);
  
  const [listCandidatesShow, setListCandidatesShow] = useState([]);
  const [listCandidatesInfo, setListCandidatesInfo] = useState([]);
  const { register, handleSubmit, errors } = useForm();
  const [phoneCandidate, setPhoneCandidate] = useState(null);
  const [mailCandidate, setMailCandidate] = useState([]);

  const [idCandidate, setIdCandidate] = useState(null);
  const [idPosition, setIdPosition] = useState(null);
  const [namePosition_, setNamePosition_] = useState(null);


  const openModalDetail = () => setVisibleModalDetail(!visibleModalDetail);
  const [visibleModalDetail, setVisibleModalDetail] = useState(null);
  const [titleModalDetail, setTitleModalDetail] = useState(null);

  const [descriptions, setDescriptions] = useState(null);
  
  const [reqDeseable, setReqDeseable] = useState(null);
  const [reqIndispenable, setReqIndispenable] = useState(null);
  const [rateOrientative, setRateOrientative] = useState(null);
  const [togglePortalChange, setTogglePortalChange] = useState("");
  useEffect(() => {
    getListCandidate();
  }, [0])
 
  useEffect(() => {
    clearInputs();
    if(togglePortalChange === 1){
      setListPositionData(listPositionDataReservForFilter.filter((item) => item.is_portal === 1));
      setListPositionDataReserv(listPositionDataReservForFilter.filter((item) => item.is_portal === 1));
    }else if (togglePortalChange === 2){
      setListPositionData(listPositionDataReservForFilter.filter((item) => item.is_portal === 0));
      setListPositionDataReserv(listPositionDataReservForFilter.filter((item) => item.is_portal === 0));
    } else {
      setListPositionData(listPositionDataReservForFilter);
      setListPositionDataReserv(listPositionDataReservForFilter);
    }
  }, [togglePortalChange])
  
  const getListCandidate = async () => {

    try {
      let dataList = [];
      let dataListExcel = [];

      get("api/position/history/candidate").then((result) => {
        if (result.data.length > 0) {
          let idPo = 1;
          result.data.forEach(element => {

            
            let excelItem = {
              "position_id": element.position_id,
              "name": element.name,
              "candidate_id": element.candidate_id ?? '',
              "candidate_name": element.candidate_name ?? '',
              "date_register": element.date_register,
              //"action": element.action ?? '', 
              "candidate_status" : element.candidate_status,
              "recruier": element.name_recruiter, 
            }

            let item = {
              "id": idPo++,
              "position_id": element.position_id,
              "name": element.name,
              "candidate_id": element.candidate_id ?? '',
              "candidate_name": element.candidate_name ?? '',
              "date_register": element.date_register,
              "action": element.action ?? '', 
              "candidate_status" : element.candidate_status,
              "recruier": element.name_recruiter, 
            }

            dataList.push(item);
            dataListExcel.push(excelItem);
          });
          setListPositionData(dataList);
          setListPositionDataReserv(dataList);
          setListPositionDataReservForFilter(dataList);
          setListPositionExcelData(dataListExcel);
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error)
    }
  }


  const tableColumns = [
    /*{
      name: 'Id',
      selector: 'position_id',
      sortable: true,
      //center: true,
      width: '120px',
    },*/
    {
      name: 'Vacante',
      selector: 'name',
      sortable: true,
      center: false,
      width: '250px',
    },
    /*{
      name: 'Id Candidato',
      selector: 'candidate_id',
      sortable: true,
      width: '150px'
    },*/
    {
      name: 'Nombre Candidato',
      selector: 'candidate_name',
      sortable: true,
      center: false,
      width: '250px',
    },
    {
      name: 'Fecha de Registro',
      selector: 'date_register',
      sortable: true,
      center: false,
      width: '150px',
    },
    /*{
      name: 'Acción Realizada',
      selector: 'action',
      center: false,
      sortable: true,
      width: '200px'
    },*/
    {
      name: 'Estado del Candidato',
      selector: 'candidate_status',
      sortable: true,
      center: false,
      width: '250px',
    },
    {
      name: 'Recluter',
      selector: 'recruier',
      sortable: true,
      center: false,
      width: '250px',
    },
  ]


  // Modal ------------------------------------------------------------------------------------- Modal
  const closeModal = () => {
    setVisibleModalAddAddCandidates(false);
    setVisibleModalListCandidate(false);
    setVisibleModalDeletePosition(false);

    setListCandidatesShow([]);
    setListCandidatesInfo([]);
    setPhoneCandidate('');
    setIdPosition('');
    setNamePosition_('');
    setMailCandidate('');
  };

  const handleToggleVisibleSearch = () => {
    setVisibleSearch(!visibleSearch);
  };

  const handleSearch = (e) => {
    
    const tempTableData = listPositionDataReserv;
    let textSearch = e.target.value;
    let filteredData;
    //let tempSearchTextClient = searchTextClient;
    //let tempSearchTextCodePosition= searchTextCodePosition ;
    //let tempSearchTextPriority = searchTextPriority;
    //let tempSearchTextStatus = searchTextStatus;
    let tempSearchTextPosition = searchTextPosition;
    let tempSearchTextCandidate = searchTextCandidate;
    switch (e.target.name) {
      /*case "client_search":
        setSearchTextClient(textSearch)
        tempSearchTextClient = textSearch;
        break;
      case "code_position_search":
        setSearchTextCodePosition(textSearch)
        tempSearchTextCodePosition = textSearch;
        break;*/
      case "position_search":
        setSearchTextPosition(textSearch)
        tempSearchTextPosition = textSearch;  
        break;
      case "candidate_search":
        setSearchTextCandidate(textSearch)
        tempSearchTextCandidate = textSearch;  
        break;
      /*case "priority_search":
        setSearchTextPriority(textSearch)
        tempSearchTextPriority = textSearch;
        break;
      case "status_search":
        setSearchTextStatus(textSearch)
        tempSearchTextStatus = textSearch;
        break;*/
    }

    filteredData = tempTableData.filter((item) => item.name.toLowerCase().includes((tempSearchTextPosition).toLowerCase()));
    filteredData = filteredData.filter((item) => item.candidate_name.toLowerCase().includes((tempSearchTextCandidate).toLowerCase()));
    
    
    //filteredData = tempTableData.filter((item) => item.name_client.toLowerCase().includes((tempSearchTextClient).toLowerCase()));
    //filteredData = filteredData.filter((item) => item.code_position.toLowerCase().includes((tempSearchTextCodePosition).toLowerCase()));
    //filteredData = filteredData.filter((item) => getBadgeTextContent(item.name).toLowerCase().includes((tempSearchTextPosition).toLowerCase()));
    //filteredData = filteredData.filter((item) => item.priority.toLowerCase().includes((tempSearchTextPriority).toLowerCase()));
    //filteredData = filteredData.filter((item) => getBadgeTextContent(item.priority).toLowerCase().includes((tempSearchTextPriority).toLowerCase()));
    //filteredData = filteredData.filter((item) => getBadgeTextContent(item.enabled).toLowerCase().includes((tempSearchTextStatus).toLowerCase()));
    //filteredData = filteredData.filter((item) => item.enabled.toLowerCase().includes((tempSearchTextStatus).toLowerCase()));
    setListPositionData(filteredData);

    console.log("bus", filteredData)

    let dataListExcel = [];
    filteredData.forEach(element => {
      
      console.log(element)
      let excelItem = {
        "position_id": element.position_id,
        "name": element.name,
        "candidate_id": element.candidate_id ?? '',
        "candidate_name": element.candidate_name ?? '',
        "date_register": element.date_register,
        //"action": element.action ?? '', 
        "candidate_status" : element.candidate_status,
        "recruier": element.name_recruiter, 
      }
      //console.log(excelItem)
      dataListExcel.push(excelItem);
    })

    setListPositionExcelData(dataListExcel);
  };

  /*
  const getBadgeTextContent = (badgeElement) => {
    if(badgeElement.props != undefined){
      const childrenArray = React.Children.toArray(badgeElement.props.children);
      return childrenArray.map((child) => child.props ? child.props.children : child).join('');
    } else {
      return '';
    }
  };*/

  const clearInputs = () => {
    //setSearchTextClient('');
    //setSearchTextCodePosition('');
    setSearchTextPosition('');
    setSearchTextCandidate('');
    //setSearchTextPriority('');
    //setSearchTextStatus('');
  };
  
  /*
  const showDetail = async (detail) => {
    setTitleModalDetail(detail.name)
    setDescriptions(detail.description)
    setReqDeseable(detail.desirable_requirements)
    setReqIndispenable(detail.required_requirements)
    setRateOrientative(detail.orientative)
    setVisibleModalDetail(true);
  }*/

  
  const handleExport = () => {
    const worksheet = XLSX.utils.json_to_sheet(listPositionExcelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `rrhh_vacantes_${moment().format()}.xlsx`);
  }

  return (
    <Fragment>
      <Breadcrumb parent="" title="Historial de Vacantes" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                  
                <div className="toggle-container">

                  <Button color='success'  className="btn-search pull-right"
                    onClick={handleExport}>
                    <i className="fa fa-file-excel-o"></i> 
                      {" Exportar"}
                      </Button>
                  {/*<div className={`content ${visibleSearch ? 'visible' : 'hidden'}`}>
                    <Row>
                      
                      <Col className="mb-3" sm="2">
                        <div className="search-container">
                          <input type="text" className="form-control" name="position_search" value={searchTextPosition} onChange={handleSearch} placeholder="Vacante" />
                        </div>
                      </Col>
                      <Col className="mb-3" sm="2">
                        <div className="search-container">
                          <input type="text" className="form-control" name="candidate_search" value={searchTextCandidate} onChange={handleSearch} placeholder="Candidato" />
                        </div>
                      </Col>
                    </Row>
                  </div>*/}
                </div>
                <DataTable
                  columns={tableColumns}
                  data={listPositionData}
                  persistTableHead
                  searchable={true}
                  paginationPerPage={30}  
                  pagination
                  noDataComponent={<div>No hay datos disponibles</div>}
                  progressPending={loading}
                  progressComponent={<div className="loader-box"><div className="loader-9"></div></div>} // Componente personalizado para el indicador de carga
                  customStyles={{
                    head: {
                      style: {
                        textAlign: 'center',
                      },
                    },
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>


    </Fragment>
  );
};

export default CandidateList;