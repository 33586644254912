import React, {Fragment, useState,useEffect} from 'react';
import Breadcrumb from '../layout/breadcrumb'
import {useForm} from 'react-hook-form'
import five from '../assets/images/job-search/5.jpg';
import {Container,Row,Col,Card,CardBody, CardHeader, Media,Button,Form,FormGroup,Label,Input} from 'reactstrap'
import {Cancel} from "../constant";
import { toast } from 'react-toastify';
import { postWithData, get } from '../services/api'


const AddClient = (props) => {
    
    // eslint-disable-next-line
    const { register, handleSubmit, formState: { isDirty, isValid, errors }} = useForm(
        { mode: "all", shouldUseNativeValidation: true });
    const [disabledButton, setDisabledButton] = useState();


    useEffect(() => {
        /*getListTechnologies();
        getListLanguages();
        getListPosition();*/
      },[0])
    
    const onSubmit = (data, e) => {
        e.preventDefault();
        setDisabledButton(true);
        /*
        let lenguages = [];
        let technologies = [];
        let position = [];
        if(multipleTec.length > 0){
            multipleTec.forEach(item => {
                let foundTec = listTechnologiesData.find(function (element) { return element.name == item });
                technologies.push(foundTec.id)
            });
        }
        
        if(multipleLang.length > 0){
            multipleLang.forEach(item => {
                let foundLang = listLanguageData.find(function (element) { return element.name == item });
                lenguages.push(foundLang.id)
            });
        }

        if(multiplePosition.length > 0){
            multiplePosition.forEach(item => {
                let foundPos = listPositionData.find(function (element) { return element.name == item });
                position.push(foundPos.id)
            });
        }

        console.log(multipleCategory, multipleGrade)
        let category = multipleCategory;
        let grade = multipleGrade;
        */
        
        console.log(data);
        
        if (data !== '') {
            
            postWithData("api/client/add", data).then((result) => { 
              if(result.success === true){
                e.target.reset();
                toast.success("Cliente agregado correctamente.", {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
              }
            })
            .finally(()=>setDisabledButton(false));
        } else {
          errors.showMessages();
          setDisabledButton(true);
        }
      };
    
      
    /*
    const getListTechnologies = async () => {
        let dataRes = [];
        try {
            get("api/technology/list", data).then((result) => { 
                
                if(result.success == true){
                   result.data.forEach(element => {
                        dataRes.push(element.name)
                    });
                    setListTechnologiesData(result.data)
                    setListTechnologiesDataShow(dataRes)
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    const getListLanguages = async () => {
        let dataRes = [];
        try {
            get("api/language/list", data).then((result) => { 
                
                if(result.success == true){
                   result.data.forEach(element => {
                        dataRes.push(element.name)
                    });
                    setListLanguageData(result.data)
                    setListLanguageDataShow(dataRes)
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    const getListPosition = async () => {
        let dataRes = [];
        try {
            get("api/position/list", data).then((result) => { 
                
                if(result.success == true){
                   result.data.forEach(element => {
                        dataRes.push(element.name)
                    });
                    setListPositionData(result.data)
                    setListPositionDataShow(dataRes)
                }
            });
        } catch (error) {
            console.log(error)
        }
    }
    */

    /*
    const handleSelectPosition = (data) => {
        console.log(data);
        setMultiplePosition(data)
    };*/

    return (
        <Fragment>
            <Breadcrumb parent="Clientes" title="Nuevo Cliente"/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12" md="12">
                        <Card>
                            <CardHeader>
                                <Media>
                                    <img className="img-40 img-fluid m-r-20" src={five} alt="" />
                                    <Media body>
                                        <h6 className="f-w-600">{"Cliente"}</h6>
                                        <p>{"Ingresa los datos del Cliente"}</p>
                                    </Media>
                                </Media>
                            </CardHeader>
                            <CardBody>
                                
                                <div className="job-description">
                                    <h6 className="mb-0">{"Datos"} </h6>
                                    <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                                        <Row>
                                            <Col md="3 mb-3">
                                                <FormGroup>
                                                    <Label htmlFor="type_identity">{"Tipo de Documento"}:<span className="font-danger">*</span></Label>
                                                    <FormGroup className="m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                                        <div className="radio radio-primary">
                                                            <Input id="type_identity_1" type="radio" name="type_identity" value="NIF" defaultChecked innerRef={register({ required: true })}/>
                                                            <Label className="mb-0" for="type_identity_1"><span className="digits"> {"NIF"}</span></Label>
                                                        </div>
                                                        <div className="radio radio-primary">
                                                            <Input id="type_identity_2" type="radio" name="type_identity" value="CIF"  innerRef={register({ required: true })}/>
                                                            <Label className="mb-0" for="type_identity_2"><span className="digits"> {"CIF"}</span></Label>
                                                        </div>
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col md="3 mb-3">
                                                <FormGroup>
                                                    <Label htmlFor="number_identity">{"Número de Documento"}:<span className="font-danger">*</span></Label>
                                                    <Input className="form-control" id="number_identity" name="number_identity" type="text" placeholder="" innerRef={register({ required: true, maxLength: 10 })}/>
                                                    <span style={{ color: "red" }}>{errors.number_identity && 'Verifica el número de documento'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label htmlFor="name">{"Nombre"}:<span className="font-danger">*</span></Label>
                                                    <Input className="form-control"  id="name" name="name" type="text" placeholder="" innerRef={register({ required: true })} />
                                                    <span style={{ color: "red" }}>{errors.name && 'Verifica el Nombre'}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label htmlFor="legal_name">{"Nombre Legal"}:</Label>
                                                    <Input className="form-control"  id="legal_name" name="legal_name" type="text" placeholder="" innerRef={register()} />
                                                    <span style={{ color: "red" }}>{errors.legal_name && 'Verifica el Nombre Legal'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label htmlFor="email">{"Email"}:<span className="font-danger">*</span></Label>
                                                    <Input className="form-control"  id="email" name="email" type="email" placeholder="" innerRef={register({ required: true })} />
                                                    <span style={{ color: "red" }}>{errors.email && 'Verifica el Email'}</span>
                                                </FormGroup>
                                            </Col><Col>
                                                <FormGroup>
                                                    <Label htmlFor="phone">{"Teléfono"}:<span className="font-danger">*</span></Label>
                                                    <Input className="form-control" id="phone" name="phone" type="tel" placeholder="" innerRef={register({ required: true })} />
                                                    <span style={{ color: "red" }}>{errors.phone && 'Verifica el número de Telefono'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label htmlFor="country">{"País"}:<span className="font-danger">*</span></Label>
                                                    <Input className="form-control" id="country" name="country" type="text" placeholder="" innerRef={register()} />
                                                    <span style={{ color: "red" }}>{errors.country && 'Verifica el País'}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label htmlFor="province">{"Provincia"}:<span className="font-danger">*</span></Label>
                                                    <Input className="form-control" id="province" name="province" type="text" placeholder="" innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label htmlFor="city">{"Ciudad"}:<span className="font-danger">*</span></Label>
                                                    <Input className="form-control" id="city" name="city" type="text" placeholder="" innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label htmlFor="address">{"Dirección"}:</Label>
                                                    <Input className="form-control" id="address" name="address" type="text" placeholder="" innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label htmlFor="postal_code">{"Código Postal"}:</Label>
                                                    <Input className="form-control" id="postal_code" name="postal_code" type="text" placeholder="" innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <div className="card-footer">
                                            <Button color="primary mr-1" type="submit" disabled={!isDirty || !isValid || disabledButton}>{"Agregar Cliente"}</Button>
                                            <Button color="light">{Cancel}</Button>
                                        </div>
                                    </Form>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default AddClient;