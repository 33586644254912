import React, { useState, useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Row, Col, Button, Input } from 'reactstrap'
import { get } from '../services/api'

const TechnologyTypeahead = (props) => {
    
    const [inputTecValue, setInputTecValue] = useState('');
    const [listTechnologiesData, setListTechnologiesData] = useState([]);
    const [listTechnologiesDataReserv, setListTechnologiesDataReserv] = useState([]);
    const [multipleTec, setMultipleTec] = useState(props.multiple?props.multiple:[]);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        getListTechnologies();
    }, [0])

    const getListTechnologies = async () => {
        let dataRes = [];
        try {
            get("api/technology/list").then((result) => {

                if (result.success == true) {
                    result.data.forEach(element => {
                        dataRes.push(element.name)
                    });
                    setListTechnologiesData(result.data)
                    setListTechnologiesDataReserv(result.data)
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    const handleSelectTec = (selectedOption) => {
        console.log(props.multiple);
        const tecIds = selectedOption.map(item => item.id);
        setMultipleTec(tecIds);
        props.onTechnologySelect(tecIds);
        setListTechnologiesDataReserv(listTechnologiesData);
    };
    const handleInputChange = (inputValue) => {
        setListTechnologiesData(listTechnologiesDataReserv);
        setListTechnologiesData((prevList) => [...prevList, { id: inputValue, name: inputValue }]);
    };

    function handleInputTecChange(event) {
        setInputTecValue(event.target.value);
    }

    function handleSelectFromInput() {
        if (inputTecValue) {
            const listTechnologiesDataToLowerCase = listTechnologiesData.map(item => item.name.toLowerCase());
            const inputTec = inputTecValue.split(',').map(name => name.trim().toLowerCase());
            const selectedTec = listTechnologiesData.filter(item => inputTec.includes(item.name.toLowerCase()));
            const selectedTecWithoutId = [];
            inputTec.forEach(item => {
                if (!listTechnologiesDataToLowerCase.includes(item.toLowerCase())) {
                    selectedTecWithoutId.push({ id: item, name: item })
                    setListTechnologiesData((prevList) => [...prevList, { id: item, name: item }]);
                }
            })
            const updatedSelection = listTechnologiesData?.filter(item => multipleTec.includes(item.id)).concat(selectedTec);
            const uniqueUpdatedSelection = [...new Set(updatedSelection.map(JSON.stringify))].map(JSON.parse);
            handleSelectTec(uniqueUpdatedSelection.concat(selectedTecWithoutId));
            setInputTecValue('');
            setVisible(!visible);
        }
    }

    const handleToggleVisible = () => {
        setVisible(!visible);
    }
    return (

        <div>
            <Row>
                <Col md={6} sm={12} className="align-self-center">
                    <Typeahead
                        id="id"
                        labelKey="name"
                        onChange={handleSelectTec}
                        multiple={true}
                        options={listTechnologiesData}
                        selected={listTechnologiesData?.filter(item => multipleTec.includes(item.id))}
                        placeholder=""
                        onInputChange={handleInputChange}
                    />
                </Col>
                <Col md={6} sm={12}>
                    <Button color="primary mr-1" onClick={handleToggleVisible}>
                        <span><i className="fa fa-plus text-white"></i>{" "}</span>
                        {"Pegar Tecnologías"}
                    </Button>
                </Col>
            </Row>
            <div className="mb-2"> </div>
            <Row className={`content ${visible ? 'visible' : 'hidden'}`}>
                <Col md={6} sm={12}>
                    <Input
                        type="text"
                        value={inputTecValue}
                        onChange={handleInputTecChange}
                        placeholder="Ingrese las tecnologías separadas por comas"
                    />
                </Col>
                <Col md={6} sm={12}>
                    <Button color="primary mr-1" onClick={handleSelectFromInput}><span><i className="fa fa-plus text-white"></i>{" "}</span></Button>
                </Col>
            </Row>
            <div className="mb-3"> </div>
        </div>

    );
};

export default TechnologyTypeahead;