import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../layout/breadcrumb'
import { useForm } from 'react-hook-form'
import { Typeahead } from 'react-bootstrap-typeahead';
import { useParams } from 'react-router-dom'
import Select from 'react-select'
import one from '../assets/images/job-search/1.jpg';
import {
    Container, Row, Col, Card, CardBody, CardHeader, Media, Button, Form, FormGroup, Label,
    Input, InputGroup, InputGroupAddon, InputGroupText
} from 'reactstrap'
import { Cancel } from "../constant";
import { toast } from 'react-toastify';
import { tableData } from '../data/dummyTableData'
import { get, postWithDataMultiform } from '../services/api'
import TechnologyTypeahead from './technology-typeahead';

const AddCandidate = (props) => {

    let { id_candidate } = useParams();

    const { register, handleSubmit, errors } = useForm();
    const [dataAdd, setDataAdd] = useState({})
    const [data, setData] = useState(tableData)

    const [listLanguageData, setListLanguageData] = useState([]);
    const [listOriginShow, setListOriginShow] = useState([]);
    const [listOrigin, setListOrigin] = useState([]);
    const [multipleTec, setMultipleTec] = useState([]);
    const [multipleLang, setMultipleLang] = useState([]);
    const [academic, setAcademic] = useState('');
    const [origin, setOrigin] = useState('');
    const [category, setCategory] = useState('');
    const [idCandidate, setIdCandidate] = useState('');
    const [defaultLinkedin, setDefaultLinkedin] = useState('');
    const [categoryValue, setCategoryValue] = useState();
    const [originValue, setOriginValue] = useState();
    const [academicValue, setAcademicValue] = useState();
    const [academicTypeValue, setAcademicTypeValue] = useState(); 
    const [listAcademic, setListAcademic] = useState([]);
    const [listAcademicResev, setListAcademicReserv] = useState([]);
    const [multipleFormation, setMultipleFormation] = useState([]);
    let idAcademicType;
    let idOrigin;
    const [salary, setSalary] = useState('')
    // const langData = data.languages.split(",").map(str => parseInt(str));
    const [selectedTypeIdentity, setSelectedTypeIdentity] = useState('')
    const [disabledButton, setDisabledButton] = useState(false);
    useEffect(() => {
        getListOrigin();
        getDataCandidate();
        getListLanguages();
    }, [0])

    const getDataCandidate = async () => {
        try {
            get('api/candidate/' + id_candidate).then((result) => {
                if (result.data.length > 0) {
                    
                    setData(result.data[0]);
                    setSalary(data.salary)
                    if (result.data[0].languages)
                        setMultipleLang(result.data[0].languages.split(",").map(str => parseInt(str)));
                    if (result.data[0].technology)
                        setMultipleTec(result.data[0].technology.split(",").map(str => parseInt(str)))
                    if (result.data[0].type_identity)
                        setSelectedTypeIdentity(result.data[0].type_identity)
                    if (result.data[0].category != null) {
                        
                        setCategoryValue({ value: result.data[0].category, label: result.data[0].category });
                        setCategory(result.data[0].category);
                    }

                    if (result.data[0].grade != null) {
                        setAcademicValue({ value: result.data[0].grade, label: result.data[0].grade });
                        setAcademic(result.data[0].grade);
                    }
                    if (result.data[0].academic != null) {
                        idAcademicType = parseInt(result.data[0].academic);
                        setMultipleFormation(result.data[0].academic);
                    }

                    if (result.data[0].origin_id != null) {
                        
                        setOrigin(result.data[0].origin_id)
                        
                        //Cambiar esto
                        let arrayOrigin = {
                            1 : 'Linkedin Búsqueda',
                            2 : 'Email / Recomendación',
                            3 : 'Linkeding Anuncio',
                            4 : 'Infojobs Búsqueda',
                            5 : 'Infojobs Anuncio',
                            6 : 'Base de Datos',
                            7 : 'Otros'
                        };

                        setOriginValue({ value: result.data[0].origin_id, label: arrayOrigin[result.data[0].origin_id] });
                    }

                    setIdCandidate(result.data[0].id);
                    setDefaultLinkedin(result.data[0].linkedin);
                    //console.log(result.data[0]);
                    getListAcademic();

                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    const onSubmit = (data, e) => {
        e.preventDefault();
        setDisabledButton(true);
        let dataComp = {
            ...data,
            candidate_id: idCandidate,
            technologies: multipleTec.join(),
            languages: multipleLang.join(),
            grade: academic,
            academic: multipleFormation,
            category: category,
            origin: origin,
            // date_brith: startDate,
            user_id: localStorage.getItem('Id')
        }

        if (data !== '') {
            setDataAdd(dataComp);
            postWithDataMultiform("api/candidate/edit", dataComp).then((result) => {
                if (result.success == true) {
                    toast.success("La información del candidato se ha actualizado")
                    window.location.href = '/candidate/history-candidate/' + id_candidate;
                }
            })
            .finally(()=>setDisabledButton(false));
        } else {
            errors.showMessages();
            setDisabledButton(false);
        }
    };

    const getListLanguages = async () => {
        let dataRes = [];
        try {
            get("api/language/list").then((result) => {

                if (result.success == true) {
                    result.data.forEach(element => {
                        dataRes.push(element.name)
                    });
                    setListLanguageData(result.data)
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    const getListAcademic = () => {
        let dataRes = [];
        try {
            get("api/academic/list", data).then((result) => {

                if (result.success == true) {
                    result.data.forEach(element => {
                        dataRes.push({value: element.id, label: element.academic_type})
                        if(idAcademicType === element.id) {
                            handleSelectForma({value: element.id, label: element.academic_type});
                        }            
                    
                    });
                    setListAcademic(dataRes);
                    setListAcademicReserv(dataRes);

                   
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    const getListOrigin = async () => {
        try {
            let dataListOriginShow = [];
            get('api/origin/list').then((result) => {
                if (result.data.length > 0) {
                    result.data.forEach(element => {
                        let item = { value: element.id, label: element.name };
                        dataListOriginShow.push(item);
                        /*if(element.id === idOrigin) {
                            setOrigin(item);
                            setOriginValue(item);
                        }*/
                        setListOrigin(element)
                    });
                    
                    setListOriginShow(dataListOriginShow);
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    const handleSelectOrigin = (data) => {
        setOrigin(data.value)
        setOriginValue({ value: data.value, label: data.label });
    };

    const handleSelectCategory = (data) => {
        setCategory(data.value)
        setCategoryValue({ value: data.value, label: data.value });
    };

    const handleSelectAcademic = (data) => {
        setAcademic(data.value)
        setAcademicValue({ value: data.value, label: data.value });
    };

    const handleSelectLang = (selected) => {
        setMultipleLang(selected.map(item => item.id));
    };

    const handleTechnologySelection = (selectedOption) => {
        setMultipleTec(selectedOption);
    };

    const handleRadioChange = (event) => {
        setSelectedTypeIdentity(event.target.value);
    }

    const handleEducationChange = (inputValue) => {
        setListAcademic(listAcademicResev);
        setListAcademic((prevList) => [...prevList, { value: inputValue, label: inputValue }]);
    }

    const handleSelectForma = (data) => {
        //console.log(data);
        //console.log(data)

        setMultipleFormation(data.value)
        setAcademicTypeValue({ value: data.value, label: data.label });
        
    };

    const handleChangeDecimal = (event) => {
        const { value } = event.target;
    
        // Expresión regular para permitir solo números y un punto opcional para los decimales
        const regex = /^\d*\.?\d*$/;
    
        if (regex.test(value) || value === '') {
          setSalary(value)
          // Llama a la función de cambio pasada como prop
          /*if (this.props.onChange) {
            this.props.onChange(value);
          }*/
        }
      };

    return (
        <Fragment>
            <Breadcrumb parent="Candidatos" title="Editar Candidato" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12" md="12">
                        <Card>
                            <CardHeader>
                                <Media>
                                    <img className="img-40 img-fluid m-r-20" src={one} alt="" />
                                    <Media body>
                                        <h4 className="f-w-600">{"Candidato"}</h4>
                                        <p>{"Edita los datos del Candidato"}</p>
                                    </Media>
                                </Media>
                            </CardHeader>
                            <CardBody>
                                <div className="job-description">
                                    <h5 className="mb-0">{"Datos Personales"} </h5>
                                    <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                                        <Row>
                                            <Col md={6} sm={12}>
                                                <FormGroup>
                                                    <Label htmlFor="type_identity">{"Tipo de Documento"}:</Label>
                                                    <FormGroup className="m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                                        <div className="radio radio-primary">
                                                            <Input onChange={handleRadioChange} id="type_identity_1" type="radio" name="type_identity" value="DNI" checked={selectedTypeIdentity === 'DNI'} innerRef={register()} />
                                                            <Label className="mb-0" for="type_identity_1"><span className="digits"> {"DNI"}</span></Label>
                                                        </div>
                                                        <div className="radio radio-primary">
                                                            <Input onChange={handleRadioChange} id="type_identity_2" type="radio" name="type_identity" value="NIE" checked={selectedTypeIdentity === 'NIE'} innerRef={register()} />
                                                            <Label className="mb-0" for="type_identity_2"><span className="digits"> {"NIE"}</span></Label>
                                                        </div>
                                                        <div className="radio radio-primary">
                                                            <Input onChange={handleRadioChange} id="type_identity_3" type="radio" name="type_identity" value="PASAPORTE" checked={selectedTypeIdentity === 'PASAPORTE'} innerRef={register()} />
                                                            <Label className="mb-0" for="type_identity_3"><span className="digits"> {"PASAPORTE"}</span></Label>
                                                        </div>
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} sm={12}>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6} sm={12} >
                                                <FormGroup>
                                                    <Label htmlFor="number_identity">{"Número de Documento"}:</Label>
                                                    <Input className="form-control" id="number_identity"
                                                        defaultValue={data.number_identity || ''}
                                                        name="number_identity" type="text" placeholder="" innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} sm={12} >
                                                <FormGroup>
                                                    <Label htmlFor="name">{"Nombre"}:<span className="font-danger">*</span></Label>
                                                    <Input className="form-control" id="name"
                                                        name="name" type="text" required placeholder="" innerRef={register()}
                                                        defaultValue={data.name || ''} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6} sm={12} >
                                                <FormGroup>
                                                    <Label htmlFor="lastname">{"Primer Apellido"}:<span className="font-danger">*</span></Label>
                                                    <Input className="form-control" id="lastname" innerRef={register()}
                                                        defaultValue={data.lastname || ''}
                                                        name="lastname" type="text" required placeholder="" />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} sm={12} >
                                                <FormGroup>
                                                    <Label htmlFor="second_lastname">{"Segundo Apellido"}</Label>
                                                    <Input className="form-control" id="second_lastname" innerRef={register()}
                                                        defaultValue={data.second_lastname || ''}
                                                        name="second_lastname" type="text" placeholder="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {/* <Row>
                                            <Col sm="4">
                                                <div className="col-form-label pt-0">{"Fecha de Nacimiento"}</div>
                                                <FormGroup>
                                                    <DatePicker selected={startDate} className="form-control" onChange={handleChange} innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label htmlFor="nationality">{"Nacionalidad"}:</Label>
                                                    <Input className="form-control" id="nationality" name="nationality" type="text" placeholder="" innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                        </Row> */}
                                        <h5 className="mb-0">{"Datos de Contacto"} </h5>

                                        <Row>
                                            <Col md={6} sm={12} >
                                                <FormGroup>
                                                    <Label htmlFor="phone">{"Teléfono"}:<span className="font-danger">*</span></Label>
                                                    <Input className="form-control" id="phone"
                                                        defaultValue={data.phone || ''}
                                                        name="phone" type="tel" required placeholder="" innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} sm={12} >
                                                <FormGroup>
                                                    <Label htmlFor="email">{"Email"}:<span className="font-danger">*</span></Label>
                                                    <Input className="form-control" id="email"
                                                        defaultValue={data.email || ''}
                                                        name="email" type="email" required placeholder="" innerRef={register()} />
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col md={6} sm={12} >
                                                <FormGroup>
                                                    <Label htmlFor="city">{"Provincia"}:<span className="font-danger">*</span></Label>
                                                    <Input className="form-control" id="city" name="city" type="text" required placeholder="" innerRef={register()}
                                                        defaultValue={data.city || ''} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} sm={12} >
                                                <FormGroup>
                                                    <Label htmlFor="address">{"Dirección"}:</Label>
                                                    <Input className="form-control" id="address" name="address" type="text" placeholder="" innerRef={register()}
                                                        defaultValue={data.address || ''}
                                                    />
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col md={6} sm={12} >
                                                <FormGroup>
                                                    <Label htmlFor="postal_code">{"Código Postal"}:</Label>
                                                    <Input className="form-control" id="postal_code" name="postal_code" type="text" placeholder="" innerRef={register()}
                                                        defaultValue={data.postal_code || ''}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} sm={12} >
                                            </Col>
                                        </Row>

                                        <h5 className="mb-0">{"Formacion"} </h5>

                                        <Row>
                                            <Col md={6} sm={12} >
                                                <div className="col-form-label pt-0">{"Grado de Instruccion"}:</div>
                                                <FormGroup>
                                                    <Select
                                                        options={[
                                                            { value: 'Grado Medio', label: 'Grado Medio' },
                                                            { value: 'Grado Superior', label: 'Grado Superior' }
                                                        ]}
                                                        onChange={handleSelectAcademic}
                                                        id="grade" name="grade"
                                                        value={academicValue}
                                                        innerRef={register()} />

                                                </FormGroup>
                                            </Col>
                                            <Col md={6} sm={12} >
                                                <div className="col-form-label pt-0">{"Formación Técnica"}:</div>
                                                <FormGroup>
                                                    <Select
                                                        onChange={handleSelectForma}
                                                        options={listAcademic}
                                                        onInputChange={handleEducationChange}
                                                        id="formation_tec" name="formation_tec"
                                                        value={academicTypeValue}
                                                         />

                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6} sm={12} >
                                                <FormGroup>
                                                    <Label htmlFor="title">{"Titulo Obtenido"}:</Label>
                                                    <Input className="form-control" id="title" name="title" type="text" placeholder="" innerRef={register()} defaultValue={data.title || ''} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} sm={12} >
                                                <FormGroup>
                                                    <Label htmlFor="institute">{"Instituto / Universidad"}:</Label>
                                                    <Input className="form-control" id="institute" name="institute" type="text" placeholder="" innerRef={register()} defaultValue={data.institute || ''} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} sm={12} >
                                            </Col>
                                        </Row>
                                        <h5 className="mb-0">{"Skills"}</h5>
                                        <Row>
                                            <Col md={6} sm={12} >
                                                <div className="col-form-label pt-0">{"Categoría/Rol"}:</div>
                                                <FormGroup>
                                                    <Select
                                                        onChange={handleSelectCategory}
                                                        options={[
                                                            { value: 'Becario', label: 'Becario' },
                                                            { value: 'Junior', label: 'Junior' },
                                                            { value: 'Mid', label: 'Mid' },
                                                            { value: 'Senior', label: 'Senior' }]}
                                                        id="category" name="category"
                                                        value={categoryValue}
                                                        innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} sm={12} >
                                                <div className="col-form-label pt-0">{"Idiomas"}:</div>
                                                <FormGroup>
                                                    <Typeahead
                                                        id="id"
                                                        labelKey="name"
                                                        onChange={handleSelectLang}
                                                        multiple={true}
                                                        selected={listLanguageData?.filter(item => multipleLang.includes(item.id))}
                                                        options={listLanguageData}
                                                        placeholder=""
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <div className="col-form-label pt-0">{"Tecnologías"}:<span className="font-danger"></span></div>
                                        {idCandidate? <TechnologyTypeahead onTechnologySelect={handleTechnologySelection} multiple={multipleTec}/>:""}
                                        <Row>
                                            <Col md={6} sm={12} >
                                                <div className="col-form-label pt-0">{"Salario"}:<span className="font-danger">*</span></div>
                                                <FormGroup>
                                                    <Input className="form-control" 
                                                    name="salary" type="decimal" 
                                                    min="1" step="any" required
                                                    placeholder="" 
                                                    defaultValue={data.salary || ''} 
                                                    innerRef={register()}
                                                    onChange={handleChangeDecimal} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} sm={12} >
                                                <div className="col-form-label pt-0">{"Tarifa Autónomo"}:</div>
                                                <FormGroup>
                                                    <Input className="form-control" 
                                                    name="salary_letters" 
                                                    defaultValue={data.salary_letters || ''} 
                                                    type="text" placeholder=""  
                                                    innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <h5 className="mb-0">{"Extra"}</h5>
                                        <Row>
                                            <Col md={6} sm={12} >
                                                <FormGroup>
                                                    <Label className="col-form-label pt-0">{"Perfil de Linkedin"}:</Label>
                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend"><InputGroupText>{<i className="fa fa-linkedin font-primary"></i>}</InputGroupText></InputGroupAddon>
                                                        <Input className="form-control" type="text" name="linkedin" defaultValue={defaultLinkedin} placeholder="" innerRef={register()} />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} sm={12}>
                                                <FormGroup>
                                                    <Label htmlFor="origin">{"Origen"}:<span className="font-danger">*</span></Label>
                                                    <Select
                                                        onChange={handleSelectOrigin}
                                                        options={listOriginShow}
                                                        id="origin" name="origin" required
                                                        value={originValue}
                                                        innerRef={register()} />
                                                </FormGroup>
                                            </Col>
                                            {/* <Col>
                                                <FormGroup check>
                                                    <div className="checkbox checkbox-primary">
                                                        <Input id="inline-form-2" type="checkbox" />
                                                        <Label className="mb-0" htmlFor="inline-form-2">{"Es Portal"}</Label>
                                                    </div> 
                                                </FormGroup>
                                            </Col> */}
                                        </Row>

                                        <Row>
                                            <Col md={6} sm={12}>
                                                <FormGroup>
                                                    <Label className="col-form-label pt-0">{"Observación / Comentarios"}:</Label>
                                                    <Input type="textarea" className="form-control btn-square" name="description" rows="3" innerRef={register()} defaultValue={data.description || ''} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} sm={12}>
                                                <FormGroup>
                                                    <Label className="col-form-label pt-0">{"Comentario para enviar al cliente"}:</Label>
                                                    <Input type="textarea" className="form-control btn-square" name="comment_client" rows="3" innerRef={register()} defaultValue={data.comment_client || ''} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <div className="card-footer">
                                            <Button disabled={disabledButton} color="primary mr-1" type="submit">{"Guardar Cambios"}</Button>
                                            <Button type="button" onClick={() => {window.location.href = '/candidate/history-candidate/' + id_candidate}} color="light">{Cancel}</Button>
                                        </div>
                                    </Form>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default AddCandidate;