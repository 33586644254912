import React, { Fragment,useState, useEffect } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import ApexCharts from 'react-apexcharts'
import Slider from "react-slick";
import {Container,Row,Col,Card,CardBody,CardHeader,Table,Button} from 'reactstrap' 
import CountUp from 'react-countup';
//import Chart from 'react-apexcharts'
import Select from 'react-select'
import {ShoppingBag, TrendingUp, Navigation, ArrowDown, ArrowUp} from 'react-feather'
import {Monthlysales,columnCharts,totalearning,Riskfactorchart} from './chartsData/apex-charts-data'
import { withGoogleMap, GoogleMap, withScriptjs } from "react-google-maps";
import Chart from "react-google-charts";
import { Field, formatQuery, RuleGroupType } from 'react-querybuilder';
import { defaultValidator, QueryBuilder } from 'react-querybuilder';
import { defaultOperators } from 'react-querybuilder';
import { QueryBuilderDnD } from '@react-querybuilder/dnd';
import * as ReactDnD from 'react-dnd';
import * as ReactDndHtml5Backend from 'react-dnd-html5-backend';


import {NewProduct,NewsUpdate,RiskFactor,BestSeller,Location,TodayTotalSale,TodayTotalVisits,OurSaleValue,New,Hot,TotalProfit,HikeShoes,CouponCode,TreePot,Watch,TShirt,TotalGoal,GoalArchive,Duration,DownloadDetails,Johnketer,HerryVenter,Done,Pending,LoainDeo,TodayStockValue,Bag,HorenHors,InProcess,FenterJessy,Success} from '../../constant'
import DataTable from 'react-data-table-component'
import { postWithData, get } from '../../services/api'

const Ecommerce = (props) => {

  const [perfilActive, setPerfilActive] = useState(localStorage.getItem('NameRole'));

  const [loading, setLoading] = useState(true);

  const [listEficiencia, setListEficiencia] = useState([]);

  const [year, setYear] = useState('');
  const [yearBack, setYearBack] = useState('');
  const [yearOptions, setYearOptions] = useState([]);
  const [searchTextClient, setSearchTextClient] = useState('');
  
  const [N_RecursosSolicitados, setN_RecursosSolicitados] = useState('');
  const [N_CV_presentados, setN_CV_presentados] = useState('');
  const [N_CV_Entrevistados, setN_CV_Entrevistados] = useState('');
  const [CV_Optimos, setCV_Optimos] = useState('');
  const [N_Incorporados, setN_Incorporados] = useState('');
  const [SR_Incorporados, setSR_Incorporados] = useState('');
  const [Indicador_Eficiencia, setIndicador_Eficiencia] = useState('');

  const [CV_Optimos_back, setCV_Optimos_back] = useState('');
  const [SR_Incorporados_back, setSR_Incorporados_back] = useState('');
  const [Indicador_Eficiencia_back, setIndicador_Eficiencia_back] = useState('');

  const [bajoOptimo, setBajoOptimo] = useState(true);
  const [bajoIncorporados, setBajoIncorporados] = useState(true);
  const [bajoEficiencia, setBajoEficiencia] = useState(true);

  const [pipeLine, setPipeLine] = useState('');
  const [Opor_ganadas, setOpor_ganadas] = useState('');
  const [Opor_perdidas, setOpor_perdidas] = useState('');
  const [Porc_Ganadas, setPorc_Ganadas] = useState('');
  const [Porc_Perdidas, setPorc_Perdidas] = useState('');

  const [listIndicator, setListIndicator] = useState([]);
  const [listIndicatorReserva, setListIndicatorReserva] = useState([]);
  const [listClientesData, setListClientesData] = useState([]);

  const [listIndicatorRevenue, setListIndicatorRevenue] = useState([]);
  
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = String(today.getMonth() + 1).padStart(2, '0'); // Agrega un 0 al principio si el mes es menor a 10
  const currentDay = String(today.getDate()).padStart(2, '0'); // Agrega un 0 al principio si el día es menor a 10

  const initialQuery = { combinator: 'and', rules: [] };

  useEffect(() => {
    setYear(currentYear);
    setYearBack(parseInt(currentYear) + 1)
    
    getIndicadorRevenue({ year: currentYear})
    getIndicators();
    getListClients();

  }, [])

  const fields = [
    {
      name: 'CAST(date_register AS DATE)',
      label: 'Fecha',
      inputType: 'date'
    },
    {
      name: 'client_id',
      label: 'Cliente',
      valueEditorType: 'select',
      operator: "=",
      values: listClientesData
    }
  ];

  const [query, setQuery] = useState(initialQuery);


  const getIndicators = async () => {
    const dateCurrent = new Date();
    const currentYear = dateCurrent.getFullYear();
    const yearsOpctions = [];

    // Generar las opciones desde 2 años atrás hasta el año actual
    for (let year = currentYear; year >= currentYear - 2; year--) {
      yearsOpctions.push({ value: year.toString(), label: year.toString() });
    }
    setYearOptions(yearsOpctions);

    let dataInicialYear = {
      'year' : currentYear,
      'query': "",
      'dataCliente' : ""
    }

    setYear(currentYear);
    setYearBack(parseInt(currentYear) + 1)

    getIndicadores(dataInicialYear)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      console.log("ddd")
      // Simular un clic en el botón "Buscar"
      buscar();
    }
  };

  const buscar = () => {
    let queryWhereSql = formatQuery(query, 'sql');
    
    //Client id
    let dataClient = (queryWhereSql.split("client_id = '")[1]);

    let queryResult = queryWhereSql

    let data = {
      year: '',
      query: queryResult,
      dataCliente: (dataClient == undefined)? 0 : dataClient
    }

    console.log(data)
    getIndicadores(data)
  };

  const handleSelectYear = (selectedOption) => {
    setYear(selectedOption.value);
    setYearBack(parseInt(selectedOption.value) + 1)
  };

  const updateDashboard = () => {
    
    try {
      let dataYear = {
        'year' : year
      }
  
      getIndicadorRevenue(dataYear)

    } catch (error) {
      console.log(error)
    }
  };

  const getListClients = async () => {
    let dataRes = [];
    try {
        get("api/client/list").then((result) => {
            if (result.success == true) {
                result.data.forEach(element => {
                    dataRes.push({ name: element.id, label: element.name })
                });
                setListClientesData(dataRes)
            }
        });
    } catch (error) {
        console.log(error)
    }
  }

  function AmountFormatter(amount) {
    const formattedAmount = new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(amount);
  
    return formattedAmount;
  }

  function getIndicadores(data){
    try {
      let dataList = [];
      let dataListRevenue = [];

      postWithData("api/dashboard/economic/indicators", data).then((result) => {
            if (result.success == true) {
                result.data.indicator_client.forEach(element => {
                  
                  let item = {
                      "id": element.id,
                      "name": element.NAME,
                      "N_CV_presentados": element.N_CV_presentados,
                      "N_CV_Entrevistados": element.N_CV_Entrevistados,
                      "N_RecursosSolicitados": element.N_RecursosSolicitados,
                      "CV_Optimos": (element.CV_Optimos == null)? '0.00%': parseFloat(element.CV_Optimos).toFixed(2)+'%',
                      //"CV_Optimos": Math.round(element.CV_Optimos)+'%',
                      "Indicador_Eficiencia": (element.Indicador_Eficiencia == null)? '0.00%': parseFloat(element.Indicador_Eficiencia).toFixed(2)+'%',
                      //"Indicador_Eficiencia": Math.round(element.Indicador_Eficiencia)+'%',
                      "N_Incorporados": element.N_Incorporados,
                      "SR_Incorporados": (element.SR_Incorporados == null)? '0.00%': parseFloat(element.SR_Incorporados).toFixed(2)+'%'
                      //"SR_Incorporados": Math.round(element.SR_Incorporados)+'%'
                  }
                  dataList.push(item);
                });
                setListIndicator(dataList)
                setListIndicatorReserva(dataList)
                setLoading(false);

                setListEficiencia(result.data.indicator_eficiencia[0])
                let optimo_back = (result.data.indicator_eficiencia[0].CV_Optimos_back == null)? 0 : parseFloat(result.data.indicator_eficiencia[0].CV_Optimos_back).toFixed(2);
                let sr_incorporados_back  = (result.data.indicator_eficiencia[0].N_Incorporados_back == null)? 0 : parseFloat(result.data.indicator_eficiencia[0].N_Incorporados_back).toFixed(2);
                let eficiencia_back = (result.data.indicator_eficiencia[0].Indicador_Eficiencia_back == null)? 0 : parseFloat(result.data.indicator_eficiencia[0].Indicador_Eficiencia_back).toFixed(2);

                //Indicador de eficiencia
                console.log( optimo_back, sr_incorporados_back, eficiencia_back)

                if(parseFloat(result.data.indicator_eficiencia[0].CV_Optimos).toFixed(2) > optimo_back){
                  //Subio con respecto al año anterior
                  setBajoOptimo(false)
                }else{
                  //Bajo con respecto al año anterior
                  setBajoOptimo(true)
                }
                /*
                if(parseFloat(result.data.indicator_eficiencia[0].N_Incorporados).toFixed(2) > sr_incorporados_back){
                  //Subio con respecto al año anterior
                  setBajoIncorporados(false)
                }else{
                  //Bajo con respecto al año anterior
                  setBajoIncorporados(true)
                }
                */
               
                if(parseFloat(result.data.indicator_eficiencia[0].N_Incorporados).toFixed(2) > sr_incorporados_back){
                  setBajoIncorporados(true)
                }else{
                  setBajoIncorporados(false)
                }
                
                if(parseFloat(result.data.indicator_eficiencia[0].Indicador_Eficiencia).toFixed(2) > eficiencia_back){
                  //Subio con respecto al año anterior
                  setBajoEficiencia(false)
                }else{
                  //Bajo con respecto al año anterior
                  setBajoEficiencia(true)
                }
                
                setN_RecursosSolicitados(result.data.indicator_eficiencia[0].N_RecursosSolicitados)
                setN_CV_presentados(result.data.indicator_eficiencia[0].N_CV_presentados)
                setN_CV_Entrevistados(result.data.indicator_eficiencia[0].N_CV_Entrevistados)
                setCV_Optimos((result.data.indicator_eficiencia[0].CV_Optimos == null)? '0.00%': parseFloat(result.data.indicator_eficiencia[0].CV_Optimos).toFixed(2)+'%')
                setCV_Optimos_back((result.data.indicator_eficiencia[0].CV_Optimos_back == null)? '0.00%': parseFloat(result.data.indicator_eficiencia[0].CV_Optimos_back).toFixed(2)+'%')
                
                setN_Incorporados(result.data.indicator_eficiencia[0].N_Incorporados)
                setSR_Incorporados((result.data.indicator_eficiencia[0].SR_Incorporados == null)? '0.00%': parseFloat(result.data.indicator_eficiencia[0].SR_Incorporados).toFixed(2)+'%')
                setSR_Incorporados_back((result.data.indicator_eficiencia[0].SR_Incorporados_back == null)? '0.00%': parseFloat(result.data.indicator_eficiencia[0].SR_Incorporados_back).toFixed(2)+'%')
                
                setIndicador_Eficiencia(parseFloat((result.data.indicator_eficiencia[0].Indicador_Eficiencia == null)? '0.00%': result.data.indicator_eficiencia[0].Indicador_Eficiencia).toFixed(2)+'%')
                setIndicador_Eficiencia_back(parseFloat((result.data.indicator_eficiencia[0].Indicador_Eficiencia_back == null)? '0.00%': result.data.indicator_eficiencia[0].Indicador_Eficiencia_back).toFixed(2)+'%')


                //Indicador de ventas
                setPipeLine((result.data.indicator_ventas[0].pipeLine == null)? '0 €': AmountFormatter(result.data.indicator_ventas[0].pipeLine));
                setOpor_ganadas((result.data.indicator_ventas[0].op_ganadas == null)? '0 €': AmountFormatter(result.data.indicator_ventas[0].op_ganadas));
                setOpor_perdidas((result.data.indicator_ventas[0].op_perdidas == null)? '0 €': AmountFormatter(result.data.indicator_ventas[0].op_perdidas));
                setPorc_Ganadas((result.data.indicator_ventas[0].porc_ganadas == null)? '0.00%': parseFloat(result.data.indicator_ventas[0].porc_ganadas).toFixed(2)+'%');
                setPorc_Perdidas((result.data.indicator_ventas[0].porc_perdidas == null)? '0.00%': parseFloat(result.data.indicator_ventas[0].porc_perdidas).toFixed(2)+'%');

            }
        });
    } catch (error) {
        console.log(error)
    }
  }

  function getIndicadorRevenue(data){
    try {
      let dataList = [];
      let dataListRevenue = [];

      postWithData("api/dashboard/economic/indicatorRevenue", data).then((result) => {
            if (result.success == true) {
                
                //Indicador Revenue
                result.data.indicator_revenue.forEach(element => {
                  console.log(element)
                  let item = {
                      "backlog": element.backlog,
                      "year_1": AmountFormatter(element.year_1),
                      "year_2": AmountFormatter(element.year_2),
                      "total": AmountFormatter(element.total)
                  }

                  dataListRevenue.push(item);
                });

                setListIndicatorRevenue(dataListRevenue)
            }
        });
    } catch (error) {
        console.log(error)
    }
  }

  const radialChartIndicatorGlobal = {
    options: {
      legend: {
          show: false
      },
      colors: ["#fb2e63"],
      dataLabels: {
          enabled: false
      },
      plotOptions: {
          radialBar: {
              startAngle: -135,
              endAngle: 135,
              dataLabels: {
                  name: {
                      fontSize: '16px',
                      color: '#000000',
                      offsetY: 120,
                      formatter: function (val) {
                        let valReturn = '';
                        if(val.indexOf("1") !== -1){
                          //'N_RecursosSolicitados'
                          valReturn = 'Nº Recursos Solicitados';
                        }else if(val.indexOf("2") !== -1){
                          //'N_CV_presentados'
                          valReturn = 'Nº CV presentados';
                        }else if(val.indexOf("3") !== -1){
                          //'N_CV_Entrevistados'
                          valReturn = 'Nº CV Entrevistados';
                        }else if(val.indexOf("4") !== -1){
                          //'N_Incorporados'
                          valReturn = 'Nº Incorporados';
                        }
                        
                        return valReturn;
                      }
                  },
                  value: {
                      offsetY: 76,
                      fontSize: '22px',
                      color: '#000000',
                      formatter: function (val) {
                          return val;
                      }
                  }
              }
          }
      },
      stroke: {
          dashArray: 1
      },
      fill: {
          colors: ["#fb2e63","#fb2e63","#fb2e63"],
          opacity: 1
      }
  },
  series: [N_RecursosSolicitados, N_CV_presentados, N_CV_Entrevistados, N_Incorporados],
  labels: ['N_RecursosSolicitados', 'N_CV_presentados', 'N_CV_Entrevistados', 'N_Incorporados'],
 }
 
  const radialChartGlobalPorcentaje = {
    options: {
        legend: {
            show: false
        },
        colors: ["#fb2e63"],
        dataLabels: {
            enabled: false
        },
        plotOptions: {
            radialBar: {
                startAngle: -135,
                endAngle: 135,
                dataLabels: {
                    name: {
                        fontSize: '16px',
                        color: '#000000',
                        offsetY: 120,
                        formatter: function (val) {
                          //let valueName = replace(val, replacement)
                          let valReturn = '';
                          if(val.indexOf("1") !== -1){
                            //'CV_Optimos'
                            valReturn = 'CV Optimos';
                          }else if(val.indexOf("2") !== -1){
                            //'SR_Incorporados'
                            valReturn = 'SR Incorporados';
                          }else if(val.indexOf("3") !== -1){
                            //'Indicador_Eficiencia'
                            valReturn = 'Indicador Eficiencia';
                          }
                          
                          return valReturn;
                        }
                    },
                    value: {
                        offsetY: 76,
                        fontSize: '22px',
                        color: '#000000',
                        formatter: function (val) {
                            return val + "%";
                        }
                    }
                }
            }
        },
        stroke: {
            dashArray: 1
        },
        fill: {
            colors: ["#fb2e63","#fb2e63","#fb2e63"],
            opacity: 1
        }
    },
    series: [parseInt(CV_Optimos), parseInt(SR_Incorporados), parseInt(Indicador_Eficiencia)],
    labels: ['CV_Optimos','SR_Incorporados','Indicador_Eficiencia']
  }

  const columnCharts = {
    series: [{
      name: 'Monto',
      data: [pipeLine.replace("€", ""), Opor_ganadas.replace("€", ""), Opor_perdidas.replace("€", "")]
    }],
    options: {
      chart: {
        height: 105,
        type: 'bar',
        stacked: true,
        toolbar: {
          show: true
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top', // top, center, bottom
          },

          columnWidth: '40%',
          startingShape: 'rounded',
          endingShape: 'rounded'
        }
      },
      dataLabels: {
        enabled: false,

        formatter: function (val) {
          //return val + "%";
          let dato =  parseFloat(val.replace("€", ""));
          return dato;
        },
        offsetY: -10,
        style: {
          fontSize: '12px',
          colors: ["#fb2e63"]
        }
      },

      xaxis: {
        categories: ["Pipe Line", "Op. Ganadas", "Op. Perdidas"],
        position: 'bottom',

        axisBorder: {
          show: true
        },
        axisTicks: {
          show: true
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#BDBCBC',
              colorTo: '#c481ec',
              stops: [0, 20000000],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        },
        labels: {
          show: true
        }

      },
      yaxis: {
        axisBorder: {
          show: true
        },
        axisTicks: {
          show: true,
        },
        labels: {
          show: true,
          formatter: function (val) {
            return val;
          }
        }

      },
      grid: {
        show: true,
        padding: {
          top: -35,
          right: -45,
          bottom: -20,
          left: -10
        },
      },
      colors: ["#fb2e63"],
    }
  }

  const tableColumns = [
    {
      name:'Cliente',
      selector:'name',
      sortable:true,
      //center:true
    },
    {
      name: 'CV Presentados',
      selector: 'N_CV_presentados',
      sortable: true,
      center:true,
    },
    {
      name: 'CV Entrevistados',
      selector: 'N_CV_Entrevistados',
      sortable: true,
      center:true,
    },
    {
      name: 'Nº Recursos Solicitados',
      selector: 'N_RecursosSolicitados',
      sortable: true,
      center:true,
    },
    {
      name: 'CV Optimos',
      selector: 'CV_Optimos',
      sortable: true,
      center:true,
    },
    {
      name: 'Indicador Eficiencia',
      selector: 'Indicador_Eficiencia',
      sortable: true,
      center:true,
    },
    {
      name: 'Nº Incorporados',
      selector: 'N_Incorporados',
      sortable: true,
      center: true,
    },
    {
      name: 'SR Incorporados',
      selector: 'SR_Incorporados',
      sortable: true,
      center:true,
    }
  ]

  const tableColumnsRevenue = [
    {
      name:'Backlog',
      selector:'backlog',
      sortable:false,
      center:false
    },
    {
      name: year,
      selector: 'year_1',
      sortable: false,
      center:false,
    },
    {
      name: yearBack,
      selector: 'year_2',
      sortable: false,
      center:false,
    },
    {
      name: 'Total',
      selector: 'total',
      sortable: false,
      center:false,
    },
  ]

    // eslint-disable-next-line
    const[location,setlocation] = useState({ address: false,
      mapPosition: {
        lat: 18.5204, lng: 73.8567
        },
        markerPosition: {
        lat: 18.5204, lng: 73.8567
        }
    })

    const BasicMap = withScriptjs(
      withGoogleMap(
      props => (
        <GoogleMap google={props.google}
        defaultZoom={15}
        defaultCenter={{ lat: location.mapPosition.lat, lng: location.mapPosition.lng }}
        >
      </GoogleMap>
      )
      )
    );

    const settings = {
        className: "center",
        centerMode: true,
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        centerPadding: "5px",
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const handleSearch = (e) => {
    
      const tempTableData = listIndicatorReserva;
      
      let textSearch = e.target.value;
      let filteredData;
      let tempSearchTextClient = searchTextClient;
      setSearchTextClient(textSearch)
      tempSearchTextClient = textSearch;
      console.log("busqueda", textSearch)
      console.log("lista", tempTableData)
      filteredData = tempTableData.filter((item) => item.name.toLowerCase().includes((tempSearchTextClient).toLowerCase()));
      console.log("resultado", filteredData)
      setListIndicator(filteredData);

    };

    return (
        <Fragment>
            <Breadcrumb parent="Dashboard" title="Económico" />
            <Container fluid={true}>
            
              <div>
                <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
                  <QueryBuilder
                    fields={fields}
                    query={query}
                    onQueryChange={(q) => setQuery(q)}
                    showCombinatorsBetweenRules
                    validator={defaultValidator}
                    controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
                    onKeyPress={handleKeyPress}
                  />
                </QueryBuilderDnD>
                <br />
                <Button className="btn-search" id="btnBuscar" color="primary" onClick={buscar}>{"Actualizar"}</Button>
              </div>
            <Row className="size-column">
              <Col xl="4 xl-50" className="box-col-12">
                <Card className="o-hidden">
                  <CardHeader>
                    <h5>KPI's Indicadores globales</h5>
                  </CardHeader>
                  <CardBody className="pt-0">
                    <Row>
                      <Col xs="12">
                        <div id="chart-widget5">
                          <div className="table-responsive">
                            <Table borderless>
                              <tbody className="f-w-500">
                                <tr>
                                  <td>
                                    <div className="media">
                                      <div className="media-body"><span>Nº Recursos solicitados</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <p>{N_RecursosSolicitados}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="media">
                                      <div className="media-body"><span>Nº CV's presentados</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <p>{N_CV_presentados}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="media">
                                      <div className="media-body"><span>Nº CV's Entrevistados</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <p>{N_CV_Entrevistados}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="media">
                                      <div className="media-body"><span title="Cantidad de candidatos Entrevistado PV / Entre toda la cantidad de candidatos">% CV's óptimos</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <p>{CV_Optimos}
                                    {bajoOptimo ? (
                                      <span title={"Valor del año anterior: "+CV_Optimos_back}><ArrowDown size={12} color='red' /></span>
                                      ) : (
                                      <span title={"Valor del año anterior: "+CV_Optimos_back}><ArrowUp size={12} color='green' /></span>
                                    )}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="media">
                                      <div className="media-body"><span>Incorporados</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <p>{N_Incorporados}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="media">
                                      <div className="media-body"><span title="Cantidad de candidatos Aceptados / Entre toda la cantidad de posiciones">SR Incorporados</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <p>{SR_Incorporados} 
                                    {bajoIncorporados ? (
                                      <span title={"Valor del año anterior: "+SR_Incorporados_back}><ArrowDown size={12} color='red' /></span>
                                    ) : (
                                      <span title={"Valor del año anterior: "+SR_Incorporados_back}><ArrowUp size={12} color='green' /></span>
                                    )}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="media">
                                      <div className="media-body"><span title="Cantidad de candidatos Aceptados / Entre toda la cantidad de candidatos">Indicador eficiencia</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <p>{Indicador_Eficiencia}
                                    {bajoEficiencia ? (
                                      <span title={"Valor del año anterior: "+Indicador_Eficiencia_back}><ArrowDown size={12} color='red' /></span>
                                      ) : (
                                      <span title={"Valor del año anterior: "+Indicador_Eficiencia_back}><ArrowUp size={12} color='green' /></span>
                                    )}</p>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="8 xl-50" className="box-col-12">
                <Card className="o-hidden">
                    <CardHeader>
                    <h5>{"Indicadores Globales"}</h5>
                    </CardHeader>
                    <CardBody className="pt-0">
                      <Row>
                          <Col xs="12">
                            <div id="chart-widget5">
                            <Chart
                                width={'100%'}
                                height={'320px'}
                                chartType="PieChart"
                                data={[
                                    ['Task', 'Hours per Day'],
                                    ['Recursos Solicitados', parseFloat(N_RecursosSolicitados)],
                                    ['CV Presentados', parseFloat(N_CV_presentados)],
                                    ['CV Entrevistados', parseFloat(N_CV_Entrevistados)],
                                    ['Incorporados', parseFloat(N_Incorporados)],
                                   
                                ]}
                                options={{
                                    colors: ["#a927f9", "#51bb25" , "#1E24EC", "#f8d62b"],
                                    is3D: true
                                }}
                                rootProps={{ 'data-testid': '2' }}
                            />
                            </div>
                          </Col>
                      </Row>
                    </CardBody>
                </Card>
              </Col>

              
            </Row>
          <Row className="second-chart-list third-news-update">
            <Col sm="12">
              <Card>
                <CardHeader className="card-no-border">
                  <h5>KPI's Indicadores por Cliente</h5>
                </CardHeader>
                <CardBody className="pt-0">
                  <Row>
                    <Col className="mb-3" sm="2">
                      <div className="search-container">
                        <input type="text" className="form-control" name="client_search" value={searchTextClient} onChange={handleSearch} placeholder="Buscar Cliente" />
                      </div>
                    </Col>
                  </Row>
                  <DataTable
                    data={listIndicator}
                    pagination
                    columns={tableColumns}
                    striped={true}
                    persistTableHead
                    searchable={true}
                    paginationPerPage={40}  
                    noDataComponent={<div>No hay datos disponibles</div>}
                    progressPending={loading}
                    progressComponent={<div className="loader-box"><div className="loader-9"></div></div>} // Componente personalizado para el indicador de carga
                    customStyles={{
                      head: {
                        style: {
                          textAlign: 'center',
                        },
                      },
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="second-chart-list third-news-update">
            <Col xl="4 xl-50" className="box-col-12">
              <Card>
                <CardHeader className="card-no-border">
                  <h5>KPI's Indicadores por Ventas</h5>
                </CardHeader>
                <CardBody className="pt-0">
                  <div className="our-product">
                    <div className="table-responsive">
                      <Table borderless>
                        <tbody className="f-w-500">
                          <tr>
                            <td>
                              <div className="media">
                                <div className="media-body"><span>Pipe Line</span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <p>{pipeLine}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="media">
                                <div className="media-body"><span>Oportunidades ganadas</span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <p>{Opor_ganadas}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="media">
                                <div className="media-body"><span>Oportunidades perdidas</span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <p>{Opor_perdidas}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="media">
                                <div className="media-body"><span>% Ganadas</span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <p>{Porc_Ganadas}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="media">
                                <div className="media-body"><span>% Perdidas</span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <p>{Porc_Perdidas}</p>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="8 xl-50" className="box-col-12">
              <Card className="o-hidden">
                <CardHeader className="card-no-border">
                  <div className="media">
                    <div className="media-body">
                      <p><span className="f-w-500 font-roboto">{"Oportunidades Ganadas"}</span><span className="f-w-700 font-primary ml-2">{Porc_Ganadas}</span></p>
                      <p><span className="f-w-500 font-roboto">{"Oportunidades Perdidas"}</span><span className="f-w-700 font-primary ml-2">{Porc_Perdidas}</span></p>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="pt-0">
                  <div className="monthly-visit">
                    <ApexCharts id="column-chart" 
                    options={columnCharts.options} 
                    series={columnCharts.series} type='bar' height={160} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div>
              <Select
                onChange={handleSelectYear} 
                options={yearOptions} 
                id="year" 
                name="year" />
              <br />
              <Button className="btn-search" color="primary" onClick={updateDashboard}>{"Actualizar"}</Button>
            </div>

          <Row className="second-chart-list third-news-update">
            <Col sm="12 xl-50" className="box-col-12">
              <Card>
                <CardHeader className="card-no-border">
                  <h5>KPI's Indicadores Revenue</h5>
                </CardHeader>
                <CardBody className="pt-0">
                  <DataTable
                    data={listIndicatorRevenue}
                    pagination={false}
                    columns={tableColumnsRevenue}
                    striped={true}
                    persistTableHead
                    searchable={true}
                    paginationPerPage={40}  
                    noDataComponent={<div>No hay datos disponibles</div>}
                    progressPending={loading}
                    progressComponent={<div className="loader-box"><div className="loader-9"></div></div>} // Componente personalizado para el indicador de carga
                    customStyles={{
                      head: {
                        style: {
                          textAlign: 'center',
                        },
                      },
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          
          </Container>
        </Fragment>
    );
}

export default Ecommerce;