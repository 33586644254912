const urlbase = process.env.REACT_APP_API_BASE_URL;
const idUserSesion = localStorage.getItem("Id");
const tokenBearer = localStorage.getItem("token");


export const login = async (url, data) => {
    try {
        console.log('data--1-->', data)
        return new Promise(function(resolve, reject) {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                "email": data.email,
                "password": data.password
            })
        };
        console.log('data---->', requestOptions)
        return fetch(urlbase + url, requestOptions)
        .then((response) => response.json() )
        .then((responseData) => {
            resolve(responseData);
        })
        .catch(error => console.log('error', error));
        })
  
    } catch (error) {
        console.log(error)
    }
  }

export const postWithData = async (url, data) => {
  try {

      return new Promise(function(resolve, reject) {
      const requestOptions = {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization' : `Bearer ${tokenBearer}`
        },
        body:JSON.stringify(
        {...data, 
            user_id: localStorage.getItem('Id')
        })
         
      };
      console.log('data---->', requestOptions)
      return fetch(urlbase + url, requestOptions)
      .then((response) => response.json() )
      .then((responseData) => {
          resolve(responseData);
      })
      .catch(error => console.log('error', error));
      })

  } catch (error) {
      console.log(error)
  }
}

export const postWithDataMultiform = async (url, data) => {
    try {
  
        return new Promise(function(resolve, reject) {
            const formData  = new FormData();
            formData.append('user_id', idUserSesion);
            Object.keys(data).forEach(key => {
                console.log(key, data[key]);
                if(key == 'file' || key == 'CV'){
                    formData.append('file', data[key][0]);
                }else{
                    formData.append(key, data[key]);
                }
            });
            
            const requestOptions = {
                method: 'POST',
                headers: { 'Authorization' : `Bearer ${tokenBearer}` },
                body: formData
            };
            return fetch(urlbase + url, requestOptions)
            .then((response) => response.json() )
            .then((responseData) => {
                resolve(responseData);
            })
            .catch(error => console.log('error', error));
            
        })
  
    } catch (error) {
        console.log(error)
    }
  }

  export const postWithDataMultiformFile = async (url, data) => {
    try {
  
        return new Promise(function(resolve, reject) {
            const formData  = new FormData();
            formData.append('user_id', idUserSesion);
            Object.keys(data).forEach(key => {
                if(key == 'file' || key == 'CV'){
                    for (let i = 0; i < data[key].length; i++) {
                        const file = data[key][i];
                        formData.append('files[]', file); // Usa 'files[]' para indicar que es un array en el FormData
                    }
                }else{
                    formData.append(key, data[key]);
                }
            });
            
            const requestOptions = {
                method: 'POST',
                headers: { 'Authorization' : `Bearer ${tokenBearer}` },
                body: formData
            };

            console.log(requestOptions)

            return fetch(urlbase + url, requestOptions)
            .then((response) => response.json() )
            .then((responseData) => {
                resolve(responseData);
            })
            .catch(error => console.log('error', error));
            
        })
  
    } catch (error) {
        console.log(error)
    }
  }
export const get = async (url) => {
try{
    return new Promise((resolve,reject)=>{
        const requestOptions = {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization' : `Bearer ${tokenBearer}`
            },
        };
        

        fetch(urlbase + url, requestOptions)
            .then(response=>response.json())
            .then(responseData=>resolve(responseData))
    })
}catch (error){
    console.log(error)
}

}